import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { DragSwitch } from "react-dragswitch";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { Button, Input, InputGroup, Label, Table } from "reactstrap";
import SunCalc from "suncalc";
import * as XLSX from "xlsx";
import logo from "../assets/logo.png";
import Const from "../components/Const";
import ReactTooltip from "react-tooltip";
import DaiVanClone from "./DaiVanClone";
import {
  DaiVan,
  KetLuanLaSo,
  DaiVan10Nam,
  DaiVanDay,
  DayToCanChi,
  DongTayTuTrach,
  getNamAp,
  getNamtietKhi,
  getQue,
  handleYearTietKhi,
  HourToCanChi,
  KhoiCuong,
  KhongVong,
  ThaiTuc,
  TruGio,
  KhongVong2,
  MonthToCanChi,
  Next,
  nguHanh,
  NguyetChi,
  Prev,
  solarTime,
  soNgayDaiVan,
  TangCan,
  TangCanArr,
  ThanSatDiaChi,
  ThanSatDiaChiArr,
  ThanSatThienCan,
  ThanSatThienCanArr,
  ThapThan,
  ThapThanArr,
  ThienXa,
  ThienY,
  TruongSinh,
  YearToCan,
  YearToChi,
  ThaiCung,
  MenhCung,
  hyperLinkNapAm,
  hyperLinkTruongSinh,
  hyperLinkThanSat,
  hyperLinkThanSatDiaChi,
  thanSatKhongVong,
  hoaGiapThanSat,
} from "../components/convert";
import { NhatCanDungThan } from "./renderNhatCan";

import "../scss/main.scss";
import { loading } from "../Slice/UserSlice";
import { saveData } from "../Slice/SaveDataSlice";
import SoSanh from "./SoSanh";
export const MainContent = ({
  activeTab,
  isHistory,
  dataNote,
  tietKhiDate = [],
  tietKhiInYear = [],
}) => {
  const [excelLichvanNien, setExcelLichvanNien] = useState([]);
  const isActiveTinhCach = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveTinhCach;
  const isActiveDungThanDieuHau = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveDungThanDieuHau;

  const isActiveNguHanh = JSON.parse(
    localStorage.getItem("profile")
  )?.isActiveNguHanh;

  const [checked, setChecked] = useState(false);
  const [city, setCity] = useState(Const.city);

  const [dateData, setDateData] = useState(Const.initDateData);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("Nam");
  const [hour, setHour] = useState("null");
  const [minute, setminute] = useState("00");
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("0");
  const [year, setYear] = useState(new Date().getFullYear());

  // so sánh

  // so sánh

  const [hourShow, setHourShow] = useState("00");
  const [minuteShow, setminuteShow] = useState("00");
  const [dayShow, setDayShow] = useState("01");
  const [monthShow, setMonthShow] = useState("01");
  const [yearShow, setYearShow] = useState(new Date().getFullYear());
  const [noiSinh, setNoiSinh] = useState("");
  const [show, setShow] = useState(false);
  const [checkSelected, setCheckSelected] = useState(false);

  const [kinhDo, setKinhDo] = useState();
  const [viDo, setViDo] = useState();

  const [thienCanYear, setThienCanYear] = useState("");
  const [DiachiYear, setDiachiYear] = useState("");
  const [napAmYear, setnapAmYear] = useState("");
  const [thienCanHour, setThienCanHour] = useState("");
  const [diaChiHour, setdiaChiHour] = useState("");
  const [napAmHour, setnapAmHour] = useState("");
  const [thienCanDay, setthienCanDay] = useState("");
  const [linkHoaGiap, setLinkHoaGiap] = useState();
  const [diaChiDay, setdiaChiDay] = useState("");
  const [napAmDay, setnapAmDay] = useState("");
  const [thienCanMonth, setthienCanMonth] = useState("");
  const [diaChiMonth, setdiaChiMonth] = useState("");
  const [napAmMonth, setnapAmMonth] = useState("");
  const [stateKhongVong, setstateKhongVong] = useState("");
  const [stateThaiTuc, setStateThaiTuc] = useState("");

  const [tangCanNguHanhHour, settangCanNguHanhHour] = useState("");

  const [tangCanNguHanhHour2, settangCanNguHanHourHour2] = useState("");
  const [tangCanNguHanhHour3, settangCanNguHanHourHour3] = useState("");
  const [tangCanNguHanhHour4, settangCanNguHanHourHour4] = useState("");

  const [tangCanNguHanhHour5, settangCanNguHanHourHour5] = useState("");
  const [tangCanNguHanhHour6, settangCanNguHanHourHour6] = useState("");

  const [tangCanNguHanhDay, settangCanNguHanhDay] = useState("");
  const [tangCanNguHanhDay2, settangCanNguHanhDay2] = useState("");
  const [tangCanNguHanhDay3, settangCanNguHanhDay3] = useState("");
  const [tangCanNguHanhDay4, settangCanNguHanhDay4] = useState("");
  const [tangCanNguHanhDay5, settangCanNguHanhDay5] = useState("");
  const [tangCanNguHanhDay6, settangCanNguHanhDay6] = useState("");

  const [tangCanMonth, settangCanMonth] = useState("");
  const [tangCanMonth2, settangCanMonth2] = useState("");
  const [tangCanMonth3, settangCanMonth3] = useState("");
  const [tangCanMonth4, settangCanMonth4] = useState("");
  const [tangCanMonth5, settangCanMonth5] = useState("");
  const [tangCanMonth6, settangCanMonth6] = useState("");

  const [tangCanYear, settangCanYear] = useState("");
  const [tangCanYear2, settangCanYear2] = useState("");
  const [tangCanYear3, settangCanYear3] = useState("");
  const [tangCanYear4, settangCanYear4] = useState("");
  const [tangCanYear5, settangCanYear5] = useState("");
  const [tangCanYear6, settangCanYear6] = useState("");

  const [thapThanHour, setthapThanHour] = useState("");
  const [thapThanMonth, setthapThanMonth] = useState("");
  const [thapThanYear, setthapThanYear] = useState("");

  const [thapThanHour1, setthapThanHour1] = useState("");
  const [thapThanHour2, setthapThanHour2] = useState("");
  const [thapThanHour3, setthapThanHour3] = useState("");

  const [thapThanDay1, setthapThanDay1] = useState("");
  const [thapThanDay2, setthapThanDay2] = useState("");
  const [thapThanDay3, setthapThanDay3] = useState("");

  const [thapThanMonth1, setthapThanMonth1] = useState("");
  const [thapThanMonth2, setthapThanMonth2] = useState("");
  const [thapThanMonth3, setthapThanMonth3] = useState("");

  const [thapThanYear1, setthapThanYear1] = useState("");
  const [thapThanYear2, setthapThanYear2] = useState("");
  const [thapThanYear3, setthapThanYear3] = useState("");

  const [checkNapAm, setcheckNapAm] = useState(true);
  const [checkDungThan, setCheckDungThan] = useState(false);

  const [checkTruongSinh, setcheckTruongSinh] = useState(true);
  const [checkThanSat, setcheckThanSat] = useState(true);

  const [thanSatDiachiHours, setthanSatDiachiHours] = useState("");
  const [thanSatDiaChiDay, setthanSatDiaChiDay] = useState([]);
  const [thanSatDiachiMonth, setthanSatDiachiMonth] = useState("");
  const [thanSatDiaChiYear, setthanSatDiaChiYear] = useState("");

  const [thanSatThienCanHour, setthanSatThienCanHour] = useState("");
  const [thanSatThienCanDay, setthanSatThienCanDay] = useState("");
  const [thanSatThienCanMonth, setthanSatThienCanMonth] = useState("");
  const [thanSatThienCanYear, setthanSatThienCanYear] = useState("");

  const [truongSinhHour, settruongSinhHour] = useState("");
  const [truongSinhDay, settruongSinhDay] = useState("");
  const [truongSinhMonth, settruongSinhMonth] = useState("");
  const [truongSinhYear, settruongSinhYear] = useState("");

  const [thienCanDaiVan, setthienCanDaiVan] = useState("");
  const [nguHanhTCDV, setNguHanhTCDV] = useState("");

  const [diaChiDaiVan, setdiaChiDaiVan] = useState("");
  const [ThapThanDaiVan, setThapThanDaiVan] = useState("");

  const [TangCanDaiVan, setTangCanDaiVan] = useState("");
  const [TangCanDaiVan2, setTangCanDaiVan2] = useState("");
  const [TangCanDaiVan3, setTangCanDaiVan3] = useState("");
  const [ThapThanDaiVan1, setThapThanDaiVan1] = useState("");
  const [ThapThanDaiVan2, setThapThanDaiVan2] = useState("");
  const [ThapThanDaiVan3, setThapThanDaiVan3] = useState("");
  const [TangCanDaiVan4, setTangCanDaiVan4] = useState("");
  const [TangCanDaiVan5, setTangCanDaiVan5] = useState("");
  const [TangCanDaiVan6, setTangCanDaiVan6] = useState("");

  const [TruongSinhDaiVan, setTruongSinhDaiVan] = useState("");

  const [ThanSatThienCanDaiVan, setThanSatThienCanDaiVan] = useState("");
  const [ThanSatDiaChiDaiVan, setThanSatDiaChiDaiVan] = useState("");

  const [daiVanData, setdaiVanData] = useState([]);
  const [napAmDaiVan, setnapAmDaiVan] = useState("");

  const [huongTot, sethuongTot] = useState("");
  const [huongXau, sethuongXau] = useState("");

  const [tangCanDaiVanArr, settangCanDaiVanArr] = useState([]);

  const [alerError, setAlerError] = useState(false);

  const [alerError2, setAlerError2] = useState(false);

  const [daiVan10NamA, setdaiVan10NamA] = useState([]);

  const [CurrentYearDaiVan, setCurrentYearDaiVan] = useState();

  const [LuuNienState, setLuuNienState] = useState(new Date().getFullYear());

  const [ThienCanLuuNien, setThienCanLuuNien] = useState("");
  const [nguHanhLN, setNguHanhLN] = useState();
  const [thapthanLuuNien, setThapThanLuuNien] = useState("");

  const [DiaChiLuuNien, setDiaChiLuuNien] = useState("");
  const [TangCanLuuNien, setTangCanLuuNien] = useState("");
  const [TangCanLuuNien2, setTangCanLuuNien2] = useState("");
  const [TangCanLuuNien3, setTangCanLuuNien3] = useState("");
  const [TangCanLuuNien4, setTangCanLuuNien4] = useState("");
  const [TangCanLuuNien5, setTangCanLuuNien5] = useState("");
  const [TangCanLuuNien6, setTangCanLuuNien6] = useState("");
  const [ThapThanLuuNien1, setThapThanLuuNien1] = useState("");
  const [ThapThanLuuNien2, setThapThanLuuNien2] = useState("");
  const [ThapThanLuuNien3, setThapThanLuuNien3] = useState("");
  const [napAmLuuNien, setnapAmLuuNien] = useState("");
  const [TruongSinhLuuNien, setTruongSinhLuuNien] = useState("");
  const [ThanSatThienCanLuuNien, setThanSatThienCanLuuNien] = useState("");
  const [ThanSatDiaChiLuuNien, setThanSatDiaChiLuuNien] = useState("");

  const [gioSolar, setgioSolar] = useState("");
  const [solarphut, setSolarphut] = useState("");
  const [nguHanhHour, setnguHanhHour] = useState("");
  const [nguHanhDay, setnguHanhDay] = useState("");
  const [nguHanhMonth, setnguHanhMonth] = useState("");
  const [nguHanhYear, setnguHanhYear] = useState("");

  const [TSDiaChiArr, setTSDiaChiArr] = useState([]);
  const [TSDiaChiYearArr, seTSDiaChiYearArr] = useState([]);
  const [TSThienCanArr, setTSThienCanArr] = useState([]);
  const [TSThienCanYearArr, setTSThienCanYearArr] = useState([]);
  const [thienXa, setThienXa] = useState("");
  const [thienYDV, setThienYDV] = useState("");
  const [nguyetChiDV, setNguyetChiDV] = useState("");
  const [khoiCuongDV, setKhoiCuongDV] = useState("");

  const [thienYLN, setThienYLN] = useState("");
  const [nguyetChiLN, setNguyetChiLN] = useState("");
  const [khoiCuongLN, setKhoiCuongLN] = useState("");

  const [khoiCuongDay, setKhoiCuongDay] = useState("");

  const [objectDV, setobjectDV] = useState();
  const [soNam, setSoNam] = useState();
  const [soNgay, setSoNgay] = useState();
  const [KhongVongDaiVan, setKhongVongDaiVan] = useState();
  const [KhongVongLN, setKhongVongLN] = useState();
  const [KhongVongHour, setKhongVongHour] = useState();
  const [KhongVongDay, setKhongVongDay] = useState();
  const [KhongVongMonth, setKhongVongMonth] = useState();
  const [KhongVongYear, setKhongVongYear] = useState();
  const [DVDays, setDVdays] = useState();
  const [reload, setReload] = useState(0);

  const [checkMinute, setCheckMinute] = useState(true);

  const [checkHour, setCheckHour] = useState(false);
  const [thaiCungThienCan, setThaiCungThienCan] = useState();
  const [thaiCungDiaChi, setThaiCungDiaChi] = useState();

  const [menhCungTC, setmenhCungTC] = useState();
  const [menhCungDC, setmenhCungDC] = useState();

  const [noLogin, setNoLogin] = useState(true);

  const [NhatCan, setNhatCan] = useState("");
  const [showIsLogin, setShowIsLogin] = useState(false);
  const [dungThanActive, setDungThanActive] = useState(false);
  const [stateTruGio, setStateTruGio] = useState("");

  const [yearDaiVanClone, setYearDaiVanClone] = useState({
    year: 2022,
    soTuoi: 0,
    show: false,
  });

  const [cachCuc, setCachCuc] = useState("");
  const [dungThanPhuUc, setDungThanPhuUc] = useState("");
  const [dungThanThongQuan, setDungThanThongQuan] = useState("");
  const [dungThanDieuHau, setDungThanDieuHau] = useState("");
  const [dungThan, setDungThan] = useState("");
  const [checkThangDungThan, setCheckThangDungThan] = useState(false);
  const [luuNienDisable, setLuuNienDisable] = useState(false);


  const yearRef = useRef();
  const soTuoi = useRef();
  const thienCanNgayRef = useRef();

  useEffect(() => {
    if (reload) {
      dispatch(loading(true));
      handleResult();
    }
  }, [reload]);

  useEffect(() => {
    loadExcel();
  }, []);
  useEffect(() => {}, [thienCanHour, thienCanDay, thienCanMonth, thienCanYear]);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      const current = new Date();
      const expired = new Date(
        JSON.parse(localStorage.getItem("profile"))?.expiredDate
      );

      const email = JSON.parse(localStorage.getItem("profile"))?.email;
      const name = JSON.parse(localStorage.getItem("profile"))?.name;
      const dungThan = JSON.parse(
        localStorage.getItem("profile")
      )?.isActiveDungThan;
      setCheckDungThan(dungThan);
      const activeDate = new Date(
        JSON.parse(localStorage.getItem("profile"))?.activeDate
      );
      const expiredDate = new Date(
        JSON.parse(localStorage.getItem("profile"))?.expiredDate
      );

      if (current < expired) {
        return;
      } else {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            name: name,
            email: email,
            isActive: false,
            isActiveHoaGiap: false,
            isActiveDungThan: false,
            isActiveDungThanDieuHau: false,
            isActiveNguHanh: false,
            isActiveNguoiNoiTieng: false,
            isActiveTieuNhi: false,
            isActiveTinhCach: false,
            isActiveThanSat: false,
            activeDate: activeDate,
            expiredDate: expiredDate,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isHistory) {
      setcheckNapAm(JSON.parse(sessionStorage.getItem("dataHistory")).napAm);
      setName(JSON.parse(sessionStorage.getItem("dataHistory")).name);
      setcheckTruongSinh(
        JSON.parse(sessionStorage.getItem("dataHistory")).truongSinh
      );
      setcheckThanSat(
        JSON.parse(sessionStorage.getItem("dataHistory")).thanSat
      );
      setNoiSinh(JSON.parse(sessionStorage.getItem("dataHistory")).born || "");
      setName(JSON.parse(sessionStorage.getItem("dataHistory")).name);
      setDay(
        JSON.parse(sessionStorage.getItem("dataHistory")).date.split("/")[0]
      );

      setMonth(
        +JSON.parse(sessionStorage.getItem("dataHistory")).date.split("/")[1] -
          1
      );

      setYear(
        +JSON.parse(sessionStorage.getItem("dataHistory")).date.split("/")[2]
      );

      if (
        JSON.parse(sessionStorage.getItem("dataHistory")).time.split(":")[0] !==
        "00"
      ) {
        setHour(
          JSON.parse(sessionStorage.getItem("dataHistory")).time.split(":")[0]
        );

        setminute(
          JSON.parse(sessionStorage.getItem("dataHistory")).time.split(":")[1]
        );

        setCheckMinute(true);
      } else {
        setHour("null");
        setminute("00");
      }
    }
  }, [isHistory]);

  const setAlerErrors = () => {
    setAlerError(true);
  };

  const dispatch = useDispatch();

  const handleCheckBox1 = () => {
    setcheckNapAm(!checkNapAm);
  };
  const handleCheckBox2 = () => {
    setcheckTruongSinh(!checkTruongSinh);
  };
  const handleCheckBox3 = () => {
    setcheckThanSat(!checkThanSat);
  };

  const handlePrevDaiVan = () => {
    let prevObj = daiVanData.filter((x) => x.year === CurrentYearDaiVan - 10);

    let next = +soNgay;
    let nextTo = +soNam;

    if (prevObj?.length) {
      next -= 10;
      nextTo -= 10;
      setSoNgay(next);
      setSoNam(nextTo);
    }

    let thapThanPrev = ThapThanArr(dateData[1]?.canChi, prevObj);
    let napAmPrev = getNamAp(prevObj[0]?.thienCan + " " + prevObj[0]?.diaChi);
    let prevTruongSinh = TruongSinh(dateData[1]?.canChi, prevObj[0]?.diaChi);
    let prevThanSatTC = ThanSatThienCan(
      dateData[1]?.canChi,
      prevObj[0]?.thienCan
    );
    let prevThanSatDC = ThanSatDiaChi(dateData[1]?.diaChi, prevObj[0]?.diaChi);
    let thienY = ThienY(prevObj[0]?.diaChi);
    // let nguyetChi = NguyetChi(prevObj[0]?.diaChi);
    let khoiCuong = KhoiCuong(prevObj[0]?.thienCan, prevObj[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      prevObj[0]?.diaChi
    );

    if (prevObj[0]?.year) {
      setKhongVongDaiVan(khongVong);
      setCurrentYearDaiVan(prevObj[0]?.year);
      setNguHanhTCDV(prevObj[0]?.nguHanhTC);

      setthienCanDaiVan(prevObj[0]?.thienCan);
      setThapThanDaiVan(prevObj[0]?.thapThan?.sinhKhac);

      setdiaChiDaiVan(prevObj[0]?.diaChi);
      setTangCanDaiVan(prevObj[0]?.TangCan[0]?.name);
      setTangCanDaiVan2(prevObj[0]?.TangCan[1]?.name);
      setTangCanDaiVan3(prevObj[0]?.TangCan[2]?.name);
      setTangCanDaiVan4(prevObj[0]?.TangCan[0]?.nguHanh);
      setTangCanDaiVan5(prevObj[0]?.TangCan[1]?.nguHanh);
      setTangCanDaiVan6(prevObj[0]?.TangCan[2]?.nguHanh);

      setThapThanDaiVan1(thapThanPrev[0]?.thapThan?.sinhKhac);
      setThapThanDaiVan2(thapThanPrev[0]?.thapThan2?.sinhKhac);
      setThapThanDaiVan3(thapThanPrev[0]?.thapThan3?.sinhKhac);

      setnapAmDaiVan(napAmPrev?.napAm);
      setTruongSinhDaiVan(prevTruongSinh);

      setThanSatDiaChiDaiVan(prevThanSatDC);
      setThanSatThienCanDaiVan(prevThanSatTC);
      setThienYDV(thienY);
      // setNguyetChiDV(nguyetChi)
      setKhoiCuongDV(khoiCuong);
    } else setCurrentYearDaiVan(CurrentYearDaiVan);
  };

  const handleNextDaiVan = () => {
    let nextObj = daiVanData.filter((x) => x.year === CurrentYearDaiVan + 10);
    let next = +soNgay;
    let nextTo = +soNam;

    if (nextObj?.length) {
      next += 10;
      nextTo += 10;
      setSoNgay(next);
      setSoNam(nextTo);
    }

    let thapThanNext = ThapThanArr(dateData[1]?.canChi, nextObj);
    let napAmNext = getNamAp(nextObj[0]?.thienCan + " " + nextObj[0]?.diaChi);
    let nextTruongSinh = TruongSinh(dateData[1]?.canChi, nextObj[0]?.diaChi);
    let nextThanSatTC = ThanSatThienCan(
      dateData[1]?.canChi,
      nextObj[0]?.thienCan
    );

    let nextThanSatDC = ThanSatDiaChi(dateData[1]?.diaChi, nextObj[0]?.diaChi);
    let thienY = ThienY(nextObj[0]?.diaChi);
    // let nguyetChi = NguyetChi(nextObj[0]?.diaChi);
    let khoiCuong = KhoiCuong(nextObj[0]?.thienCan, nextObj[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      nextObj[0]?.diaChi
    );
    if (nextObj[0]?.year) {
      setKhongVongDaiVan(khongVong);
      setNguHanhTCDV(nextObj[0]?.nguHanhTC);
      setCurrentYearDaiVan(nextObj[0]?.year);
      setthienCanDaiVan(nextObj[0]?.thienCan);
      setdiaChiDaiVan(nextObj[0]?.diaChi);
      setThapThanDaiVan(nextObj[0]?.thapThan?.sinhKhac);
      setTangCanDaiVan(nextObj[0]?.TangCan[0]?.name);
      setTangCanDaiVan2(nextObj[0]?.TangCan[1]?.name);
      setTangCanDaiVan3(nextObj[0]?.TangCan[2]?.name);
      setTangCanDaiVan4(nextObj[0]?.TangCan[0]?.nguHanh);
      setTangCanDaiVan5(nextObj[0]?.TangCan[1]?.nguHanh);
      setTangCanDaiVan6(nextObj[0]?.TangCan[2]?.nguHanh);

      setThapThanDaiVan1(thapThanNext[0]?.thapThan?.sinhKhac);
      setThapThanDaiVan2(thapThanNext[0]?.thapThan2?.sinhKhac);
      setThapThanDaiVan3(thapThanNext[0]?.thapThan3?.sinhKhac);

      setnapAmDaiVan(napAmNext?.napAm);
      setTruongSinhDaiVan(nextTruongSinh);

      setThanSatDiaChiDaiVan(nextThanSatDC);
      setThanSatThienCanDaiVan(nextThanSatTC);
      setThienYDV(thienY);
      // setNguyetChiDV(nguyetChi)
      setKhoiCuongDV(khoiCuong);
    } else setCurrentYearDaiVan(CurrentYearDaiVan);
  };

  const handlePrevLuuNien = () => {
    const arr = Prev(LuuNienState, dateData[1]?.canChi, dateData[1]?.diaChi);
    let thienY = ThienY(arr[0]?.diaChi);
    // let nguyetChi = NguyetChi(arr[0]?.diaChi);
    let khoiCuong = KhoiCuong(arr[0]?.thienCan, arr[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      arr[0]?.diaChi
    );
    const thapThanLuuNien = ThapThan(dateData[1]?.canChi, arr[0]?.thienCan);

    setLuuNienState(arr[0]?.year);
    setLuuNienDisable(true);

    setThienCanLuuNien(arr[0]?.thienCan);
    setThapThanLuuNien(thapThanLuuNien?.sinhKhac);
    setNguHanhLN(arr[0]?.nguHanh);
    setDiaChiLuuNien(arr[0]?.diaChi);
    setTangCanLuuNien(arr[0]?.tangCan[0]?.name);
    setTangCanLuuNien2(arr[0]?.tangCan[1]?.name);
    setTangCanLuuNien3(arr[0]?.tangCan[2]?.name);
    setTangCanLuuNien4(arr[0]?.tangCan[0]?.nguHanh);
    setTangCanLuuNien5(arr[0]?.tangCan[1]?.nguHanh);
    setTangCanLuuNien6(arr[0]?.tangCan[2]?.nguHanh);
    setKhongVongLN(khongVong);
    setThapThanLuuNien1(arr[0]?.thapThanArr[0]?.thapThan?.sinhKhac);
    setThapThanLuuNien2(arr[0]?.thapThanArr[0]?.thapThan2?.sinhKhac);
    setThapThanLuuNien3(arr[0]?.thapThanArr[0]?.thapThan3?.sinhKhac);

    setnapAmLuuNien(arr[0]?.napAm);
    setTruongSinhLuuNien(arr[0]?.truongSinh);
    setThanSatDiaChiLuuNien(arr[0]?.thanSatDiaChi);
    console.log("Cuong xem gif ne",hyperLinkThanSat(ThanSatDiaChiLuuNien),arr[0]?.thanSatDiaChi);
    setThanSatThienCanLuuNien(arr[0]?.thanSatThienCan);
    setThienYLN(thienY);
    // setNguyetChiLN(nguyetChi)
    setKhoiCuongLN(khoiCuong);
    setLuuNienDisable(false);

  };

  const handleNextLuuNien = async () => {
    try {
      const arr = await Next(LuuNienState, dateData[1]?.canChi, dateData[1]?.diaChi);
      let thienY = ThienY(arr[0]?.diaChi);
      let khoiCuong = KhoiCuong(arr[0]?.thienCan, arr[0]?.diaChi);
      let khongVong = KhongVong2(
        dateData[1]?.canChi + " " + dateData[1]?.diaChi,
        arr[0]?.diaChi
      );
      const thapThanLuuNien = ThapThan(dateData[1]?.canChi, arr[0]?.thienCan);

      setLuuNienState(arr[0]?.year);
      setLuuNienDisable(true);
      setThienCanLuuNien(arr[0]?.thienCan);
      setThapThanLuuNien(thapThanLuuNien?.sinhKhac);
      setNguHanhLN(arr[0]?.nguHanh);
      setDiaChiLuuNien(arr[0]?.diaChi);
      setTangCanLuuNien(arr[0]?.tangCan[0]?.name);
      setTangCanLuuNien2(arr[0]?.tangCan[1]?.name);
      setTangCanLuuNien3(arr[0]?.tangCan[2]?.name);
      setTangCanLuuNien4(arr[0]?.tangCan[0]?.nguHanh);
      setTangCanLuuNien5(arr[0]?.tangCan[1]?.nguHanh);
      setTangCanLuuNien6(arr[0]?.tangCan[2]?.nguHanh);
      setKhongVongLN(khongVong);
      setThapThanLuuNien1(arr[0]?.thapThanArr[0]?.thapThan?.sinhKhac);
      setThapThanLuuNien2(arr[0]?.thapThanArr[0]?.thapThan2?.sinhKhac);
      setThapThanLuuNien3(arr[0]?.thapThanArr[0]?.thapThan3?.sinhKhac);

      setnapAmLuuNien(arr[0]?.napAm);
      setTruongSinhLuuNien(arr[0]?.truongSinh);
      setThanSatDiaChiLuuNien(arr[0]?.thanSatDiaChi);
      setThanSatThienCanLuuNien(arr[0]?.thanSatThienCan);
      setThienYLN(thienY);
      setKhoiCuongLN(khoiCuong);
      setLuuNienDisable(false);

    } catch (error) {
      console.error("Lỗi khi xử lý năm lưu niên tiếp theo:", error);
    }
  };

  const handlePrevYear = async () => {
    // await loadExcel();

    let years = +year;
    years = years - 1;

    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };

  const handleNextYear = async () => {
    //  await loadExcel();

    let years = +year;
    years = years + 1;
    if (years > 2040) {
      return;
    }
    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };

  const handlePrevMonth = async () => {
    //  await loadExcel();

    let months = +month;

    months = months - 1;

    let years = +year;

    if (months === -1) {
      months = 11;
      years = +year - 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const handleNextMonth = async () => {
    //  await loadExcel();

    let months = +month;
    months = months + 1;

    // months = months + 1;

    let years = +year;

    if (months === 12) {
      months = 0;
      years = +year + 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const namSinh = () => {
    const items = [];

    for (let i = 2040; i >= 0; --i) {
      if (i === 2023) items.push(<option selected>{i}</option>);
      else items.push(<option>{i}</option>);
    }

    return items;
  };

  const handlePrevDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;
    days = days - 1;

    if (days === 0) {
      months = months - 1;
      if (months === -1) {
        months = 11;
        years = years - 1;
      }

      let dayInMonth = moment(
        `${years}-${months + 1}`,
        "YYYY-MM"
      ).daysInMonth(); // 29

      days = dayInMonth;
    }

    let dayz = moment(`${year}-${months}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };

  const handleNextDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;

    let dayInMonth = moment(`${year}-${months + 1}`, "YYYY-MM").daysInMonth(); // 29

    days = days + 1;

    if (days > dayInMonth) {
      days = 1;
      months = months + 1;
      if (months === 12) {
        months = 0;
        years = years + 1;
      }
    }
    let dayz = moment(`${year}-${months + 1}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };

  const handlePrevHour = () => {
    let hours = +hour;
    let days = +day;
    let months = +month;
    let years = year;
    let dayInMonth;

    hours -= 2;

    if (hours < 0) {
      if (hours === 24) {
        hours = 0;
      }
      hours = 22;
      days -= 1;
      if (days === 0) {
        months -= 1;

        dayInMonth = moment(`${years}-${months + 1}`, "YYYY-MM").daysInMonth(); // 29
        days = dayInMonth;

        if (months === -1) {
          months = 11;
          years -= 1;
          dayInMonth = moment(
            `${years}-${months + 1}`,
            "YYYY-MM"
          ).daysInMonth(); // 29
          days = dayInMonth;
        }
      }
    }

    let dayz = moment(
      `${years}-${months + 1}-${days} ${hours}:${minute}`,
      "YYYY-MM-DD HH:mm"
    ); // 29

    dayz = dayz.format("D");

    setgioSolar(hours);
    setDayShow(dayz);
    setMonthShow(months);
    setYearShow(years);
    setMonth(months);
    setYear(years);

    setHour(hours);
    setDay(days);
    setReload(reload + 1);
  };

  const handleNextHour = async () => {
    //  await loadExcel();

    let hours = +hour;
    let days = +day;
    let months = +month;
    let years = year;

    let curent = moment(`${years}-${months}`, "YYYY-MM").daysInMonth(); // 29

    hours += 2;

    if (hours === 25) {
      hours = 1;
      days += 1;
      if (days > curent) {
        days = 1;
        months += 1;
        if (months > 11) {
          months = 0;
          years += 1;
        }
      }
    } else if (hours === 24) {
      hours = 0;
      days += 1;
      if (days > curent) {
        days = 1;
        months += 1;
        if (months > 11) {
          months = 0;
          years += 1;
        }
      }
    }
    setMonth(months);
    setYear(years);
    let dayz = moment(`${years}-${months}-${days}`, "YYYY-MM-DD"); // 29
    let montz = dayz.format("M");
    dayz = dayz.format("DD");

    setgioSolar(hours);
    setDayShow(dayz);
    setMonthShow(montz);
    setYearShow(years);

    setHour(hours);
    setDay(days);
    setReload(reload + 1);
  };

  async function handleResult() {
    try {
      dispatch(loading(true));

      if (JSON.parse(localStorage.getItem("profile"))?.isActive) {
        let tempTime;
        let checkYear = false;
        if (hour !== "null") {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            hour + "",
            minute + "",
            "00"
          );
          setCheckHour(false);
        } else {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            0 + "",
            minute + "",
            "00"
          );

          setCheckHour(true);
        }

        if (year >= 0 && year <= 100) {
          tempTime.setFullYear(+year + 1000, month, day, hour, minute);
          checkYear = true;
        } else {
          checkYear = false;
        }

        if (excelLichvanNien) {
          getTietKhiAllYear(tempTime);
          const lichtietKHI = getNamtietKhi(tempTime, excelLichvanNien);
          get24TietKhiOfYear(tempTime);
          const dateTietKhi = getCorrecTietKhi(tempTime);

          // const yearExcel = getYearExcel(dateTietKhi);
          // const luuNienYear = new Date(`${LuuNienState}`);
          const namTietKhi = handleYearTietKhi(
            dateTietKhi.getFullYear(),
            gender
          ); // huong tot huong xau
          const dongTayTuTrach = DongTayTuTrach(namTietKhi);

          const canYear = YearToCan(dateTietKhi.getFullYear()); //

          const chiYear = YearToChi(dateTietKhi.getFullYear());

          const canChiDay = DayToCanChi(tempTime.toDateString());

          const canChiMonth = MonthToCanChi(
            canYear.name,
            dateTietKhi?.getMonth()
          ); //
          const khongVong = KhongVong(canChiDay?.name);
          const thaiTuc = ThaiTuc(canChiDay?.name);

          const napAmNgay = getNamAp(canChiDay?.name);
          sethuongTot(dongTayTuTrach[0]?.huongTot);
          sethuongXau(dongTayTuTrach[0]?.huongXau);
          setHourShow(tempTime?.getHours());
          setminuteShow(tempTime?.getMinutes());

          setThienCanYear(canYear?.name);
          setDiachiYear(chiYear?.name);
          setstateKhongVong(khongVong);
          setStateThaiTuc(thaiTuc);

          let chiHour = null;
          let canDay = null;
          let queNgay = null;
          let chiNgay = "";
          let chiMonthLunar = "";
          let suntime;

          if (checkSelected) {
            let times = SunCalc.getTimes(tempTime, viDo, kinhDo); // tg mat troi`
            suntime = solarTime(times.solarNoon, tempTime);

            if (suntime.hour >= 0 && suntime.hour <= 9) {
              let solargio = `0${suntime.hour}`;
              setgioSolar(solargio);
            } else {
              setgioSolar(suntime.hour);
            }

            if (suntime.minutes >= 0 && suntime.minutes <= 9) {
              let solarphut = `0${suntime.minutes}`;
              setSolarphut(solarphut);
            } else {
              setSolarphut(suntime.minutes);
            }
          } else {
            if (hour != "null") setgioSolar(hour);
            else setgioSolar("00");
            setSolarphut(minute);
          }

          const initData = dateData.map((item) => {
            if (item.header === "Năm") {
              item.canChi = canYear?.name;
              item.diaChi = chiYear?.name;
              item.qua = getQue(canYear?.name, chiYear?.name)?.tenque;
              item.que = getQue(canYear?.name, chiYear?.name);
            } else if (item.header === "Tháng") {
              //tách chuỗi name trong canChiMonth
              let tempCan = canChiMonth?.name.split(" ");

              item.canChi = tempCan[0];
              item.diaChi = tempCan[1];
              item.qua = getQue(tempCan[0], tempCan[1]).tenque;
              item.que = getQue(tempCan[0], tempCan[1]);
              chiMonthLunar = tempCan[1];
            } else if (item.header === "Ngày") {
              let tempCanDay = canChiDay.name.split(" ");
              item.canChi = tempCanDay[0];
              item.diaChi = tempCanDay[1];
              // let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
              // let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

              item.qua = getQue(tempCanDay[0], tempCanDay[1]);
              item.que = getQue(tempCanDay[0], tempCanDay[1]);
              queNgay = item.que;
              chiNgay = tempCanDay[1];
            } else if (item.header === "Giờ") {
              let tempCanDay = canChiDay.name.split(" ");
              let tempCanChiHour;
              if (checkSelected) {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  suntime.hour
                ).name.split(" ");
              } else {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  tempTime.getHours()
                ).name.split(" ");
              }

              item.canChi = tempCanChiHour[0];
              item.diaChi = tempCanChiHour[1];
              item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
              item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
              chiHour = tempCanChiHour;
              canDay = tempCanDay[0];
            }

            return item;
          });

          const khongVongH = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[0].diaChi
          );
          const khongVongD = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[1].diaChi
          );
          const khongVongM = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[2].diaChi
          );
          const khongVongY = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[3].diaChi
          );

          const truGio = TruGio(dateData[0].canChi, hour, minute);

          setKhongVongDay(khongVongD);
          setKhongVongHour(khongVongH);
          setKhongVongMonth(khongVongM);
          setKhongVongYear(khongVongY);
          setDateData(initData);
          setStateTruGio(truGio);

          setThienCanHour(dateData[0].canChi);
          setdiaChiHour(dateData[0].diaChi); // dia chi gio
          setnapAmHour(dateData[0].que.napAm);

          setthienCanDay(dateData[1].canChi); //thien can ngay
          thienCanNgayRef.current = dateData[1].canChi;
          setdiaChiDay(dateData[1].diaChi); // dia chi ngay
          setnapAmDay(dateData[1].que.napAm);

          const linkHoaGiap = hoaGiapThanSat(
            dateData[1].canChi + " " + dateData[1].diaChi
          );
          setLinkHoaGiap(linkHoaGiap);

          const tsTCArr = ThanSatThienCanArr(dateData[1].canChi);
          const tsTCYearArr = ThanSatThienCanArr(dateData[3].canChi);
          setTSThienCanArr(tsTCArr);
          setTSThienCanYearArr(tsTCYearArr);

          const nguHanhTCHour = nguHanh(dateData[1].canChi, dateData[0].canChi);
          const nguHanhTCDay = nguHanh(dateData[1].canChi, dateData[1].canChi);
          const nguHanhTCMonth = nguHanh(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const nguHanhTCYear = nguHanh(dateData[1].canChi, dateData[3].canChi);
          setnguHanhHour(nguHanhTCHour);
          setnguHanhDay(nguHanhTCDay);
          setnguHanhMonth(nguHanhTCMonth);
          setnguHanhYear(nguHanhTCYear);

          const thapThanHourResult = ThapThan(
            dateData[1]?.canChi,
            dateData[0]?.canChi
          );
          const thapThanMonthResult = ThapThan(
            dateData[1]?.canChi,
            dateData[2]?.canChi
          );
          const thapThanYearResult = ThapThan(
            dateData[1]?.canChi,
            dateData[3]?.canChi
          );

          const resultTruongSinhHour = TruongSinh(
            dateData[1].canChi,
            dateData[0].diaChi
          );
          const resultTruongSinhDay = TruongSinh(
            dateData[1].canChi,
            dateData[1].diaChi
          );
          const resultTruongSinhMonth = TruongSinh(
            dateData[1].canChi,
            dateData[2].diaChi
          );
          const resultTruongSinhYear = TruongSinh(
            dateData[1].canChi,
            chiYear?.name
          );

          const TSArr = ThanSatDiaChiArr(dateData[1].diaChi);

          const TSYearArr = ThanSatDiaChiArr(dateData[3].diaChi);
          let data = [];
          let dataYear = [];
          data = [...TSArr];
          data = [...data, ...tsTCArr];

          dataYear = [...TSYearArr];
          dataYear = [...dataYear, ...tsTCYearArr];

          const thanSatDayArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[1].diaChi
          );

          const thanSatYearArr = dataYear?.filter((item) => {
            if (item?.giap.includes(dateData[3]?.diaChi)) {
              return item?.name;
            }
          });

          setthanSatDiaChiDay(thanSatDayArr);
          setTSDiaChiArr(TSArr);
          seTSDiaChiYearArr(TSYearArr);
          setthanSatDiaChiYear(thanSatYearArr);

          settruongSinhHour(resultTruongSinhHour);
          settruongSinhDay(resultTruongSinhDay);
          settruongSinhMonth(resultTruongSinhMonth);
          settruongSinhYear(resultTruongSinhYear);

          const thanSatHourArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[0].diaChi
          );

          const thanSatMonthArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[2].diaChi
          );

          setthanSatDiachiHours(thanSatHourArr);
          setthanSatDiachiMonth(thanSatMonthArr);

          const thanSatHourCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[0].canChi
          );
          const thanSatDayCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[1].canChi
          );

          const thanSatMonthCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const thanSatYearCan = ThanSatThienCan(dateData[1].canChi, canYear);

          setthanSatThienCanHour(thanSatHourCan);
          setthanSatThienCanDay(thanSatDayCan);
          setthanSatThienCanMonth(thanSatMonthCan);
          setthanSatThienCanYear(thanSatYearCan);

          setthapThanHour(thapThanHourResult?.sinhKhac);
          setthapThanMonth(thapThanMonthResult?.sinhKhac);
          setthapThanYear(thapThanYearResult?.sinhKhac);

          setthienCanMonth(dateData[2].canChi);
          setdiaChiMonth(dateData[2].diaChi);
          setnapAmMonth(dateData[2].que.napAm);

          setnapAmYear(dateData[3]?.que?.napAm);

          const TangCanHourArr = TangCan(dateData[0]?.diaChi);

          const TangCanDayArr = TangCan(dateData[1]?.diaChi);

          const TangCanMonthArr = TangCan(dateData[2]?.diaChi);

          const TangCanYearArr = TangCan(dateData[3]?.diaChi);

          const ketLuan = KetLuanLaSo(
            TangCanMonthArr[0].nguHanh,
            TangCanYearArr[0].nguHanh,
            TangCanDayArr[0].nguHanh,
            TangCanHourArr[0].nguHanh,
            nguHanhTCYear,
            nguHanhTCMonth,
            nguHanhTCHour,
            nguHanhTCDay,
            dateData[2]?.diaChi,
            dateData[0]?.canChi,
            dateData[2]?.canChi,
            dateData[3]?.canChi
          );

          setCachCuc(ketLuan[0].cachCuc);
          setDungThanPhuUc(ketLuan[0].dungThanPhuUc);
          setDungThan(ketLuan[0].dungThan);
          setDungThanThongQuan(ketLuan[0].thongQuan);
          setDungThanDieuHau(ketLuan[0].dungThanDieuHau);
          setCheckThangDungThan(ketLuan[0].checkThang);
          const thapThanHour1 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[0]?.name
          );
          const thapThanHour2 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[1]?.name
          );
          const thapThanHour3 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[2]?.name
          );

          const thapThanDay1 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[0]?.name
          );
          const thapThanDay2 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[1]?.name
          );
          const thapThanDay3 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[2]?.name
          );

          const thapThanMonth1 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[0]?.name
          );
          const thapThanMonth2 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[1]?.name
          );
          const thapThanMonth3 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[2]?.name
          );

          const thapThanYear1 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[0]?.name
          );
          const thapThanYear2 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[1]?.name
          );
          const thapThanYear3 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[2]?.name
          );

          setthapThanHour1(thapThanHour1?.sinhKhac);
          setthapThanHour2(thapThanHour2?.sinhKhac);
          setthapThanHour3(thapThanHour3?.sinhKhac);

          setthapThanDay1(thapThanDay1?.sinhKhac);
          setthapThanDay2(thapThanDay2?.sinhKhac);
          setthapThanDay3(thapThanDay3?.sinhKhac);

          setthapThanMonth1(thapThanMonth1?.sinhKhac);
          setthapThanMonth2(thapThanMonth2?.sinhKhac);
          setthapThanMonth3(thapThanMonth3?.sinhKhac);

          setthapThanYear1(thapThanYear1?.sinhKhac);
          setthapThanYear2(thapThanYear2?.sinhKhac);
          setthapThanYear3(thapThanYear3?.sinhKhac);

          settangCanNguHanhHour(TangCanHourArr[0]?.name);
          settangCanNguHanHourHour4(TangCanHourArr[0]?.nguHanh);
          settangCanNguHanHourHour2(TangCanHourArr[1]?.name);
          settangCanNguHanHourHour5(TangCanHourArr[1]?.nguHanh);
          settangCanNguHanHourHour3(TangCanHourArr[2]?.name);
          settangCanNguHanHourHour6(TangCanHourArr[2]?.nguHanh);

          settangCanNguHanhDay(TangCanDayArr[0]?.name);
          settangCanNguHanhDay4(TangCanDayArr[0]?.nguHanh);
          settangCanNguHanhDay2(TangCanDayArr[1]?.name);
          settangCanNguHanhDay5(TangCanDayArr[1]?.nguHanh);
          settangCanNguHanhDay3(TangCanDayArr[2]?.name);
          settangCanNguHanhDay6(TangCanDayArr[2]?.nguHanh);

          settangCanMonth(TangCanMonthArr[0]?.name);
          settangCanMonth4(TangCanMonthArr[0]?.nguHanh);
          settangCanMonth2(TangCanMonthArr[1]?.name);
          settangCanMonth5(TangCanMonthArr[1]?.nguHanh);
          settangCanMonth3(TangCanMonthArr[2]?.name);
          settangCanMonth6(TangCanMonthArr[2]?.nguHanh);

          settangCanYear(TangCanYearArr[0]?.name);
          settangCanYear4(TangCanYearArr[0]?.nguHanh);
          settangCanYear2(TangCanYearArr[1]?.name);
          settangCanYear5(TangCanYearArr[1]?.nguHanh);
          settangCanYear3(TangCanYearArr[2]?.name);
          settangCanYear6(TangCanYearArr[2]?.nguHanh);

          const daiVanDays = DaiVanDay(
            tempTime,
            gender,
            nguHanhTCYear,
            lichtietKHI,
            tietKhiDate
          ); // so ngay dai van

          const soNgay = soNgayDaiVan(daiVanDays);

          const daiVan = DaiVan(
            dateTietKhi,
            soNgay,
            gender,
            nguHanhTCYear,
            dateData[2].canChi,
            dateData[2].diaChi,
            dateData[1]?.canChi,
            dateData[1]?.diaChi,
            checkYear,
            daiVanDays
          );

          yearRef.current = daiVan[0]?.year;
          soTuoi.current = daiVan[0]?.soNgay;
          setdaiVanData(daiVan);
          let objectDaivan = [];

          daiVan.map((val, idx) => {
            if (
              val?.year <= new Date().getFullYear() &&
              val?.year > new Date().getFullYear() - 10
            )
              return (objectDaivan = [...objectDaivan, daiVan[idx]]);
          });

          if (objectDaivan.length === 0) {
            objectDaivan = [...objectDaivan, daiVan[0]];
          }

          const muoiNamDaiVan = DaiVan10Nam(dateTietKhi, soNgay, checkYear);

          setdaiVan10NamA(muoiNamDaiVan);

          const arrTangCanDaiVan = TangCanArr(daiVan, dateData[1]?.canChi);

          settangCanDaiVanArr(arrTangCanDaiVan);

          let thienYDV = ThienY(objectDaivan[0]?.diaChi);
          let nguyetChiDV = NguyetChi(objectDaivan[0]?.diaChi);
          let khoiCuongDV = KhoiCuong(
            objectDaivan[0]?.thienCan,
            objectDaivan[0]?.diaChi
          );

          setobjectDV(objectDaivan);
          setKhongVongDaiVan(objectDaivan[0]?.khongVong);
          setSoNam(objectDaivan[0]?.year);
          setSoNgay(objectDaivan[0]?.soNgay);
          setThienYDV(thienYDV);
          setNguyetChiDV(nguyetChiDV);
          setKhoiCuongDV(khoiCuongDV);

          setNguHanhTCDV(objectDaivan[0]?.nguHanhTC);
          setthienCanDaiVan(objectDaivan[0]?.thienCan);
          setdiaChiDaiVan(objectDaivan[0]?.diaChi);
          setCurrentYearDaiVan(objectDaivan[0]?.year);

          const tangCanDaiVan = TangCan(objectDaivan[0]?.diaChi);

          const thapThanDaiVan = ThapThan(
            dateData[1]?.canChi,
            objectDaivan[0]?.thienCan
          );

          const ThapThanDaiVan1 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[0]?.name
          );
          const ThapThanDaiVan2 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[1]?.name
          );
          const ThapThanDaiVan3 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[2]?.name
          );

          setThapThanDaiVan(thapThanDaiVan?.sinhKhac);

          setTangCanDaiVan(tangCanDaiVan[0]?.name);
          setTangCanDaiVan2(tangCanDaiVan[1]?.name);
          setTangCanDaiVan3(tangCanDaiVan[2]?.name);

          setThapThanDaiVan1(ThapThanDaiVan1?.sinhKhac);
          setThapThanDaiVan2(ThapThanDaiVan2?.sinhKhac);
          setThapThanDaiVan3(ThapThanDaiVan3?.sinhKhac);

          setTangCanDaiVan4(tangCanDaiVan[0]?.nguHanh);
          setTangCanDaiVan5(tangCanDaiVan[1]?.nguHanh);
          setTangCanDaiVan6(tangCanDaiVan[2]?.nguHanh);

          const getNapAmDaiVan = getNamAp(
            objectDaivan[0]?.thienCan + " " + objectDaivan[0]?.diaChi
          );
          const truongSinhDaiVan = TruongSinh(
            dateData[1].canChi,
            objectDaivan[0]?.diaChi
          );
          setnapAmDaiVan(getNapAmDaiVan?.napAm);
          setTruongSinhDaiVan(truongSinhDaiVan);

          const thanSatThienCanDaiVan = ThanSatThienCan(
            dateData[1].canChi,
            objectDaivan[0]?.thienCan
          );
          const thanSatDiaChiDaiVan = ThanSatDiaChi(
            dateData[1].diaChi,
            objectDaivan[0]?.diaChi
          );
          setThanSatThienCanDaiVan(thanSatThienCanDaiVan);
          setThanSatDiaChiDaiVan(thanSatDiaChiDaiVan);

          const thienCanLuuNien = YearToCan(LuuNienState);

          const thapThanThienCanLuuNien = ThapThan(
            dateData[1]?.canChi,
            thienCanLuuNien?.name
          );

          const nguHanhln = nguHanh(dateData[1]?.canChi, thienCanLuuNien.name);
          const diaChiLuuNien = YearToChi(LuuNienState);
          const khongVongLN = KhongVong2(
            dateData[1]?.canChi + " " + dateData[1]?.diaChi,
            diaChiLuuNien?.name
          );

          const tangCanluunien = TangCan(diaChiLuuNien?.name);
          const napAmLuunien = getNamAp(
            thienCanLuuNien.name + " " + diaChiLuuNien.name
          );
          const truongsinhluunien = TruongSinh(
            dateData[1]?.canChi,
            diaChiLuuNien.name
          );
          const thansatthiencanluunien = ThanSatThienCan(
            dateData[1]?.canChi,
            thienCanLuuNien.name
          );
          const thansatdiachiluunien = ThanSatDiaChi(
            dateData[1]?.canChi,
            diaChiLuuNien.name
          );

          let thienYLN = ThienY(diaChiLuuNien.name);
          let nguyetChiLN = NguyetChi(diaChiLuuNien.name);
          let khoiCuongLN = KhoiCuong(thienCanLuuNien.name, diaChiLuuNien.name);

          setKhongVongLN(khongVongLN);
          setThienYLN(thienYLN);
          setNguyetChiLN(nguyetChiLN);
          setKhoiCuongLN(khoiCuongLN);

          setThienCanLuuNien(thienCanLuuNien.name);
          setThapThanLuuNien(thapThanThienCanLuuNien?.sinhKhac);
          setNguHanhLN(nguHanhln);
          setDiaChiLuuNien(diaChiLuuNien.name);
          setTangCanLuuNien(tangCanluunien[0]?.name);
          setTangCanLuuNien2(tangCanluunien[1]?.name);
          setTangCanLuuNien3(tangCanluunien[2]?.name);
          setTangCanLuuNien4(tangCanluunien[0]?.nguHanh);
          setTangCanLuuNien5(tangCanluunien[1]?.nguHanh);
          setTangCanLuuNien6(tangCanluunien[2]?.nguHanh);
          setnapAmLuuNien(napAmLuunien);

          const thapthanluunien1 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[0]?.name
          );
          const thapthanluunien2 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[1]?.name
          );
          const thapthanluunien3 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[2]?.name
          );

          setThapThanLuuNien1(thapthanluunien1?.sinhKhac);
          setThapThanLuuNien2(thapthanluunien2?.sinhKhac);
          setThapThanLuuNien3(thapthanluunien3?.sinhKhac);

          setTruongSinhLuuNien(truongsinhluunien);

          setThanSatThienCanLuuNien(thansatthiencanluunien);
          setThanSatDiaChiLuuNien(thansatdiachiluunien);

          const thienXa = ThienXa(
            dateData[2]?.canChi,
            dateData[1]?.canChi,
            dateData[1]?.diaChi
          );

          let khoiCuongDay = KhoiCuong(
            dateData[1]?.canChi,
            dateData[1]?.diaChi
          );

          let thaiCung = ThaiCung(dateData[2]?.canChi, dateData[2].diaChi);

          setThaiCungThienCan(thaiCung[0]?.tc);
          setThaiCungDiaChi(thaiCung[0]?.dc);

          let menhCung = MenhCung(
            dateTietKhi.getMonth(),
            tempTime.getHours(),
            canYear,
            dateTietKhi.getYear()
          );
          setmenhCungTC(menhCung[0]?.tc);
          setmenhCungDC(menhCung[0]?.dc);
          setKhoiCuongDay(khoiCuongDay);

          setThienXa(thienXa);
          setShow(true);

          const nhatCan = await NhatCanDungThan(
            dateData[1]?.canChi + " Tháng " + dateData[2]?.diaChi
          );
          setNhatCan(nhatCan);
        }
      } else if (!localStorage.getItem("profile")) {
        setAlerError(true);
      } else if (!JSON.parse(localStorage.getItem("profile"))?.isActive) {
        setAlerError2(true);
      }
    } catch (e) {
      console.log("catch", e);
    } finally {
      let timeShow = moment(`${+year}-${+month + 1}-${+day}`, "YYYY-MM-DD"); // 29
      let months = timeShow.format("MM");
      let days = timeShow.format("DD");
      const dataHour = hour !== "null" ? hour : "00";
      const dataSave = {
        email: JSON.parse(localStorage.getItem("profile"))?.email,
        data:
          timeShow.format("DD/MM/YYYY") + " " + dataHour + ":" + minute + "",
        name: name,
        born: noiSinh ? noiSinh : "Không có",
        napAm: checkNapAm ? checkNapAm : false,
        truongSinh: checkTruongSinh ? checkTruongSinh : false,
        thanSat: checkThanSat ? checkThanSat : false,
        note: dataNote,
      };

      sessionStorage.setItem("dataSave", JSON.stringify(dataSave));
      dispatch(loading(false));

      dispatch(saveData(false));
      setDayShow(days);
      setMonthShow(months);
      setYearShow(year);
    }
  }

  const getTietKhiAllYear = (timeYear) => {
    const year = timeYear.getFullYear();
    const distance = timeYear?.getFullYear() - 1800;
    let thangLe = distance * 42 + 120;
    let thangChan = distance * 42 + 122;

    if (year <= 1800 && year >= 1583) {
      let ngayGioDauThang1 = Const.tietKhi_1583_1800_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_1583_1800_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_1583_1800_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_1583_1800_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_1583_1800_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_1583_1800_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_1583_1800_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_1583_1800_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_1583_1800_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_1583_1800_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_1583_1800_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_1583_1800_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else if (year >= 0 && year <= 1582) {
      let ngayGioDauThang1 = Const.tietKhi_0_1582_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_0_1582_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_0_1582_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_0_1582_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_0_1582_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_0_1582_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_0_1582_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_0_1582_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_0_1582_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_0_1582_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_0_1582_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_0_1582_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else {
      let ngayGioDauThang1 = excelLichvanNien[thangLe][2]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = excelLichvanNien[thangLe][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = excelLichvanNien[thangLe][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = excelLichvanNien[thangLe][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = excelLichvanNien[thangLe][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = excelLichvanNien[thangLe][22]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = excelLichvanNien[thangChan][2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = excelLichvanNien[thangChan][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = excelLichvanNien[thangChan][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = excelLichvanNien[thangChan][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = excelLichvanNien[thangChan][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = excelLichvanNien[thangChan][22]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    }
  };

  const get24TietKhiOfYear = (tempTime) => {
    const tempDateTime = new Date(tempTime);

    const year = tempDateTime.getFullYear();

    const distance = tempDateTime?.getFullYear() - 1800;

    const dongNgayDauThangLe = distance * 42 + 120;

    const dongNgayCuoiThangLe = distance * 42 + 121;

    const dongNgayDauThangChan = distance * 42 + 122;

    const dongNgayCuoiThangChan = distance * 42 + 123;

    if (year <= 1800 && year >= 1583) {
      const cuoiThangLe = Const.tietKhi_1583_1800_cuoiThangLe;

      const dauThangChan = Const.tietKhi_1583_1800_thangChan;

      const dauThangLe = Const.tietKhi_1583_1800_thangLe;

      const cuoiThangChan = Const.tietKhi_1583_1800_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else if (year >= 0 && year <= 1582) {
      const cuoiThangLe = Const.tietKhi_0_1582_cuoiThangLe;

      const dauThangChan = Const.tietKhi_0_1582_thangChan;

      const dauThangLe = Const.tietKhi_0_1582_thangLe;

      const cuoiThangChan = Const.tietKhi_0_1582_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else {
      const cuoiThangLe = excelLichvanNien[dongNgayCuoiThangLe];

      const dauThangChan = excelLichvanNien[dongNgayDauThangChan];

      const dauThangLe = excelLichvanNien[dongNgayDauThangLe];

      const cuoiThangChan = excelLichvanNien[dongNgayCuoiThangChan];

      tietKhiInYear = [
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
        dauThangLe[9]?.split(" - "),
        cuoiThangLe[9]?.split(" - "),
        dauThangChan[9]?.split(" - "),
        cuoiThangChan[9]?.split(" - "),
        dauThangLe[13]?.split(" - "),
        cuoiThangLe[13]?.split(" - "),
        dauThangChan[13]?.split(" - "),
        cuoiThangChan[13]?.split(" - "),
        dauThangLe[17]?.split(" - "),
        cuoiThangLe[17]?.split(" - "),
        dauThangChan[17]?.split(" - "),
        cuoiThangChan[17]?.split(" - "),
        dauThangLe[21]?.split(" - "),
        cuoiThangLe[21]?.split(" - "),
        dauThangChan[21]?.split(" - "),
        cuoiThangChan[21]?.split(" - "),
      ];
    }
  };

  const getCorrecTietKhi = (tempTime) => {
    let day = tempTime.getDate();
    let month = tempTime.getMonth();
    let year =
      tempTime < tietKhiDate[1]
        ? tempTime.getFullYear() - 1
        : tempTime.getFullYear();

    switch (tempTime.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (
          tempTime.getTime() === tietKhiDate[0].getTime() &&
          month === tietKhiDate[0].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (
          tempTime.getTime() === tietKhiDate[1].getTime() &&
          month === tietKhiDate[1].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (
          tempTime.getTime() === tietKhiDate[2].getTime() &&
          month === tietKhiDate[2].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (
          tempTime.getTime() === tietKhiDate[3].getTime() &&
          month === tietKhiDate[3].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (
          tempTime.getTime() === tietKhiDate[4].getTime() &&
          month === tietKhiDate[4].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (
          tempTime.getTime() === tietKhiDate[5].getTime() &&
          month === tietKhiDate[5].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (
          tempTime.getTime() === tietKhiDate[6].getTime() &&
          month === tietKhiDate[6].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (
          tempTime.getTime() === tietKhiDate[7].getTime() &&
          month === tietKhiDate[7].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (
          tempTime.getTime() === tietKhiDate[8].getTime() &&
          month === tietKhiDate[8].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (
          tempTime.getTime() === tietKhiDate[9].getTime() &&
          month === tietKhiDate[9].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (
          tempTime.getTime() === tietKhiDate[10].getTime() &&
          month === tietKhiDate[10].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (
          tempTime.getTime() === tietKhiDate[11].getTime() &&
          month === tietKhiDate[11].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  };

  const handleSelect = (item) => {
    if (item === "Không chọn nơi sinh") {
      setCheckSelected(false);
      setNoiSinh("");
    } else {
      const obj = city.filter((x) => x.name === item);
      setKinhDo(obj[0]?.kinhdo);
      setViDo(obj[0]?.vido);
      setNoiSinh(obj[0]?.name);
      setCheckSelected(true);
    }
  };

  const onChangeHour = (value) => {
    setHour(value);
    if (value != "null") setCheckMinute(false);
    else setCheckMinute(true);
  };

  async function loadExcel() {
    await fetch("hitek_lich_van_nien_update.xlsx")
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((ab) => {
        const wb = XLSX.read(ab, { type: "array" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        setExcelLichvanNien(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <div className="watermark" >
        <img src={logo} />
      </div>
      <div className="title__print">CÔNG TY TNHH TƯỜNG MINH PHONG THỦY</div>
      <div className="sub__title">
        Địa chỉ: 4A Đường D7, Khu dân cư Nam Long, Phước Long B, Quận 9
      </div>
      <hr className="hr__print" />
      <div className="title__battu">Phân tích bát tự</div>

      <div className="input__group hide-to-print">
        <div className="flexx__">
          <InputGroup>
            {" "}
            <Label style={{ width: "100px" }}>Họ và tên: </Label>{" "}
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </InputGroup>

          <InputGroup style={{ maxWidth: "20%", paddingLeft: "35px" }}>
            {" "}
            <Label style={{ width: "85px" }}>Giới tính: </Label>{" "}
            <Input
              onChange={(e) => setGender(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
            >
              {" "}
              <option>Nam</option>
              <option>Nữ</option>
            </Input>
          </InputGroup>

          <InputGroup>
            <Label style={{ width: "85px" }}>Giờ sinh: </Label>{" "}
            <Input
              className={hour === "null" ? "notoption" : ""}
              onChange={(e) => {
                onChangeHour(e.target.value);
              }}
              style={{ maxWidth: "100px", marginRight: "10px" }}
              type="select"
              value={hour}
            >
              <option value={"null"}>Không chọn giờ sinh</option>
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
            </Input>
            {hour === "null" ? "" : ":"}
            {hour === "null" ? (
              ""
            ) : (
              <Input
                onChange={(e) => setminute(e.target.value)}
                style={{ maxWidth: "100px", marginLeft: "10px" }}
                type="select"
                value={minute}
              >
                <option>00</option>
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
                <option>32</option>
                <option>33</option>
                <option>34</option>
                <option>35</option>
                <option>36</option>
                <option>37</option>
                <option>38</option>
                <option>39</option>
                <option>40</option>
                <option>41</option>
                <option>42</option>
                <option>43</option>
                <option>44</option>
                <option>45</option>
                <option>46</option>
                <option>47</option>
                <option>48</option>
                <option>49</option>
                <option>50</option>
                <option>51</option>
                <option>52</option>
                <option>53</option>
                <option>54</option>
                <option>55</option>
                <option>56</option>
                <option>57</option>
                <option>58</option>
                <option>59</option>
              </Input>
            )}
          </InputGroup>
        </div>

        <div className="flexx__">
          <InputGroup style={{ marginTop: "25px", marginLeft: "1px" }}>
            <Label style={{ width: "100px" }}>Ngày sinh: </Label>
            <Input
              onChange={(e) => setDay(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
              value={day}
            >
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
              <option>31</option>
            </Input>
          </InputGroup>

          <InputGroup
            style={{ maxWidth: "20%", marginTop: "25px", paddingLeft: "35px" }}
          >
            <Label style={{ paddingRight: "35px", width: "85px" }}>
              Tháng:{" "}
            </Label>
            <Input
              onChange={(e) => setMonth(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
              value={month}
            >
              <option value={0}>01</option>
              <option value={1}>02</option>
              <option value={2}>03</option>
              <option value={3}>04</option>
              <option value={4}>05</option>
              <option value={5}>06</option>
              <option value={6}>07</option>
              <option value={7}>08</option>
              <option value={8}>09</option>
              <option value={9}>10</option>
              <option value={10}>11</option>
              <option value={11}>12</option>
            </Input>
          </InputGroup>

          <InputGroup style={{ maxWidth: "30%", marginTop: "25px" }}>
            <Label style={{ width: "85px" }}>Năm: </Label>
            <Input
              onChange={(e) => setYear(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
              value={year}
            >
              {namSinh()}
            </Input>
          </InputGroup>
        </div>
        <div className="flexx__">
          <InputGroup style={{ marginTop: "25px", maxWidth: "20%" }}>
            {checked ? (
              <>
                {" "}
                <Label style={{ width: "100px" }}>Kinh Độ:</Label>
                <Input
                  style={{ width: "100px" }}
                  type="number"
                  onChange={(e) => setKinhDo(e.target.value)}
                ></Input>{" "}
              </>
            ) : (
              <>
                {" "}
                <Label style={{ width: "100px", paddingRigh: "0" }}>
                  Nơi sinh:
                </Label>
                <Input
                  type="select"
                  value={noiSinh}
                  onChange={(e) => {
                    handleSelect(e.target.value);
                  }}
                >
                  <option value={""}>Không chọn nơi sinh</option>
                  {city?.map((x) => (
                    <option value={x?.name}>{x?.name}</option>
                  ))}
                </Input>{" "}
              </>
            )}
          </InputGroup>

          <InputGroup
            style={{
              marginTop: "25px",
              maxWidth: "13%",
              columnGap: "5px",
            }}
          >
            Chọn chi tiết:
            <DragSwitch
              className="switch__"
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
          </InputGroup>
          <InputGroup
            style={{
              marginTop: "25px",
              paddingLeft: "0",
              maxWidth: "13%",
              justifyContent: "center",
            }}
          >
            <Label style={{ margin: "0" }}>Dụng Thần: </Label>
            <Input
              addon
              type="checkbox"
              checked={checkDungThan}
              onChange={() => {
                if (
                  JSON.parse(localStorage.getItem("profile"))?.isActiveDungThan
                ) {
                  setCheckDungThan(!checkDungThan);
                } else {
                  setCheckDungThan(false);
                  setDungThanActive(true);
                }
              }}
            />
          </InputGroup>
          <InputGroup
            style={{
              marginTop: "25px",
              paddingLeft: "0",
              maxWidth: "8%",
              justifyContent: "center",
            }}
          >
            <Label style={{ margin: "0" }}>Nạp Âm: </Label>
            <Input
              selected
              addon
              type="checkbox"
              checked={checkNapAm}
              onChange={handleCheckBox1}
            />
          </InputGroup>

          <InputGroup
            style={{
              marginTop: "25px",
              maxWidth: "11%",
              justifyContent: "flex-start",
            }}
          >
            <Label style={{ margin: "0" }}>Trường Sinh: </Label>
            <Input
              selected
              addon
              type="checkbox"
              checked={checkTruongSinh}
              onChange={handleCheckBox2}
            />
          </InputGroup>

          <InputGroup
            style={{
              marginTop: "25px",
              maxWidth: "15%",
              justifyContent: "flex-start",
            }}
          >
            <Label style={{ margin: "0" }}>Thần Sát: </Label>
            <Input
              selected
              addon
              type="checkbox"
              checked={checkThanSat}
              onChange={handleCheckBox3}
            />
          </InputGroup>
        </div>
      </div>

      <div className="button__result hide-to-print">
        {" "}
        <Button
          disabled={!excelLichvanNien || excelLichvanNien.length === 0}
          onClick={handleResult}
          color="primary"
        >
          Xem Bát Tự
        </Button>
      </div>
      <div id="info__printtt">
        <div id="info__print">
          Họ và tên: <span className="fw__bold"> {name}</span> | Ngày giờ sinh:{" "}
          <span className="fw__bold">
            {" "}
            {hour != "null"
              ? `${hour}:${minute} ${day}-${monthShow}-${year}`
              : `00:${minute} ${day}-${monthShow}-${year}`}
          </span>
        </div>
        <div id="info__printt">
          Giới tính: <span className="fw__bold"> {gender}</span> | Nơi sinh:{" "}
          <span className="fw__bold"> {noiSinh}</span>
        </div>
      </div>

      {show ? (
        <div>
          <div className="main__content">
            <div className="column__  margin__print2" style={{ width: "20%" }}>
              <Table style={{ width: "100%", marginTop: "58px" }}>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgrane"
                      className="bg__orgrane"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        fontSize: "18px",
                        color: "#FFFFFF",
                        cursor: "pointer",
                      }}
                      colSpan={4}
                      data-for="than-sat"
                      data-tip="Thần Sát"
                    >
                      Thần Sát
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "103px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                      colSpan="2"
                    ></td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Ngày
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Năm
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "103px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="tuong-tinh"
                      data-tip="Tướng Tinh"
                    >
                      Tướng Tinh
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiArr[0]?.giap}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiYearArr[0]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="quy-nhan"
                      data-tip="Quý Nhân"
                    >
                      Quý Nhân
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSThienCanArr[1]?.giap}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSThienCanYearArr[1]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="van-xuong"
                      data-tip="Văn Xương"
                    >
                      Văn Xương
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSThienCanArr[0]?.giap}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSThienCanYearArr[0]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="dao-hoa"
                      data-tip="Đào Hoa"
                    >
                      Đào Hoa
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSDiaChiArr[1]?.giap}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSDiaChiYearArr[1]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="dich-ma"
                      data-tip="Dịch Mã"
                    >
                      Dịch Mã
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiArr[4]?.giap}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiYearArr[4]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="qua-tu"
                      data-tip="Quả Tú"
                    >
                      Quả Tú
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                      colSpan="2"
                    >
                      {TSDiaChiArr[5]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="co-than"
                      data-tip="Cô Thần"
                    >
                      Cô Thần
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                      colSpan="2"
                    >
                      {TSDiaChiArr[2]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="kiep-sat"
                      data-tip="Kiếp Sát"
                    >
                      Kiếp Sát
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                      colSpan="2"
                    >
                      {TSDiaChiArr[3]?.giap}
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      colSpan="2"
                      data-for="khong-vong"
                      data-tip="Không Vong"
                    >
                      Không Vong
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                      colSpan="2"
                    >
                      {stateKhongVong}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <ReactTooltip
                id="tuong-tinh"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-tuong-tinh.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="quy-nhan"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-thien-at-quy-nhan.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="van-xuong"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-van-xuong.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="dao-hoa"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-ham-tri-dao-hoa-sat.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="dich-ma"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-dich-ma.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="qua-tu"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-co-than-qua-tu-than-sat-20.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="co-than"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-co-than-qua-tu-than-sat-20.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="kiep-sat"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-kiep-sat.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="khong-vong"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/bat-tu-than-sat-khong-vong.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />

              <Table>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgranetc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      data-for="thai-cung"
                      data-tip="Thai Cung"
                    >
                      Thai <br /> Cung
                    </th>
                    <th
                      id="bg__orgranemc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      data-for="menh-cung"
                      data-tip="Mệnh Cung"
                    >
                      Mệnh <br /> Cung
                    </th>
                    <th
                      id="bg__orgranemc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      data-for="thai-tuc"
                      data-tip="Thai Tức"
                    >
                      Thai <br /> Tức
                    </th>
                    <th
                      id="bg__orgranemc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                      }}
                    >
                      Trụ <br /> Phút
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {thaiCungThienCan}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {menhCungTC}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {stateThaiTuc.split(" ")[0]}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {stateTruGio.split(" ")[0]}
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {thaiCungDiaChi}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {menhCungDC}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {stateThaiTuc.split(" ")[1]}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {stateTruGio.split(" ")[1]}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <ReactTooltip
                id="thai-cung"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/luan-giai-chi-tiet-y-nghia-thai-cung-menh-cung-thai-tuc-trong-bat-tu.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="menh-cung"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/luan-giai-chi-tiet-y-nghia-thai-cung-menh-cung-thai-tuc-trong-bat-tu.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="thai-tuc"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng{" "}
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/luan-giai-chi-tiet-y-nghia-thai-cung-menh-cung-thai-tuc-trong-bat-tu.html"
                          );
                        }}
                      >
                        Click
                      </span>{" "}
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <div style={{ pageBreakBefore: "always" }}></div>

              <div className="page-break-table">
                <Table>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        id="bg__orgrane"
                        style={{
                          width: "198px",
                          border: "1px solid black",
                          background: "#ff7400",
                          fontSize: "18px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        colspan="2"
                        data-for="huong-tot"
                        data-tip="Hướng Tốt"
                      >
                        Hướng Tốt
                      </th>
                      <th
                        id="bg__orgrane_hx"
                        style={{
                          width: "198px",
                          border: "1px solid black",
                          background: "#ff7400",
                          fontSize: "18px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        colspan="2"
                        data-for="huong-xau"
                        data-tip="Hướng Xấu"
                      >
                        Hướng Xấu
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: "center" }}>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongTot?.huongA}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongTot?.huongB}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongXau?.huongA}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongXau?.huongB}
                      </td>
                    </tr>
                    <tr style={{ textAlign: "center" }}>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongTot?.huongC}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongTot?.huongD}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongXau?.huongC}
                      </td>
                      <td
                        style={{
                          width: "99px",
                          border: "1px solid black",
                          fontSize: "12px",
                        }}
                      >
                        {huongXau?.huongD}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <ReactTooltip
                id="huong-tot"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() =>
                          window.open(
                            "https://www.phongthuytuongminh.com/nen-xay-nha-theo-huong-nao-thi-tot-cach-xac-dinh-huong-nha-hop-tuoi-hop-phong-thuy-chi-tiet-nhat"
                          )
                        }
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="huong-xau"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() =>
                          window.open(
                            "https://www.phongthuytuongminh.com/nen-xay-nha-theo-huong-nao-thi-tot-cach-xac-dinh-huong-nha-hop-tuoi-hop-phong-thuy-chi-tiet-nhat"
                          )
                        }
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <Table>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgrane"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      colSpan="1"
                      data-for="dung-than-hinh-anh"
                      data-tip="Dụng Thần Hình Ảnh"
                    >
                      Dụng Thần Hình Ảnh
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {NhatCan}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <ReactTooltip
              id="dung-than-hinh-anh"
              getContent={(dataTip) => (
                <div>
                  <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                  <p style={{ color: "blue" }}>
                    Xin vui lòng
                    <span
                      className="click-span"
                      onClick={() =>
                        window.open(
                          "https://www.phongthuytuongminh.com/dung-than-la-gi-huong-dan-cach-tim-dung-than-trong-bat-tu.html"
                        )
                      }
                    >
                      {" "}
                      Click{" "}
                    </span>
                    vào đây để tìm hiểu thêm
                  </p>
                </div>
              )}
              effect="solid"
              delayHide={500}
              delayShow={500}
              delayUpdate={500}
              place="top"
              border={true}
              type="light"
            />
            <div
              id="box__"
              className="box__right margin__print"
              style={{ maxWidth: 650 }}
            >
              <Table bordered hover>
                <thead>
                  {checkHour ? (
                    " "
                  ) : (
                    <th
                      className="hide__border__td"
                      style={{ border: "1px solid transparent" }}
                      colspan="3"
                    >
                      <div
                        className="bg__"
                        style={{
                          marginBottom: "9px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          className="hide-to-print"
                          onClick={handlePrevHour}
                          style={{
                            width: "50%",
                            border: "none",
                            borderRight: "1px solid white",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleLeft style={{ color: "white" }} />
                        </button>
                        <button
                          className="hide-to-print"
                          onClick={handleNextHour}
                          style={{
                            width: "50%",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleRight style={{ color: "white" }} />
                        </button>
                      </div>
                    </th>
                  )}

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevDay}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextDay}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevMonth}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextMonth}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevYear}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextYear}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>
                </thead>

                <tbody>
                  <tr
                    className="clr__print"
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    {checkHour ? (
                      ""
                    ) : (
                      <th
                        id="bg__h"
                        colspan="3"
                        style={{
                          width: "150px",
                          border: "3px solid #000000",
                          background: "#1B61A6",
                          fontWeight: "normal",
                          height: "64px",
                        }}
                      >
                        {gioSolar} : {solarphut}
                      </th>
                    )}
                    <th
                      id="bg__d"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {dayShow}
                    </th>
                    <th
                      id="bg__m"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {monthShow}
                    </th>
                    <th
                      id="bg__y"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {yearShow}
                    </th>
                    <th
                      id="bg__a"
                      colspan="3"
                      style={{
                        width: "58px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                      }}
                    >
                      {" "}
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th
                        colspan="3"
                        className="fz__25 clr__ border__"
                        style={{ cursor: "pointer" }}
                        data-tip={thienCanHour}
                        data-for="con-giap"
                      >
                        <sup className="fz__15">{thapThanHour}</sup>
                        {thienCanHour}
                      </th>
                    )}

                    <th
                      id="bg__tcd"
                      style={{ background: "#B2CFEA", cursor: "pointer" }}
                      colspan="3"
                      className="fz__25 border__"
                      data-for="con-giap"
                      data-tip={thienCanDay}
                    >
                      {thienCanDay}
                    </th>
                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      style={{ cursor: "pointer" }}
                      data-tip={thienCanMonth}
                      data-for="con-giap"
                    >
                      <sup className="fz__15">{thapThanMonth}</sup>
                      {thienCanMonth}
                    </th>

                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      style={{ cursor: "pointer" }}
                      data-tip={thienCanYear}
                      data-for="con-giap"
                    >
                      <sup className="fz__15">{thapThanYear}</sup>
                      {thienCanYear}
                    </th>

                    <th
                      id="bg__tc"
                      rowSpan="2"
                      style={{
                        width: "58px",
                        border: "3px solid #000000",
                        cursor: "pointer",
                      }}
                      className="bg__ clr__white clr__print"
                      data-for="thien-can"
                      data-tip="Thiên Can"
                    >
                      Thiên Can
                    </th>

                    <ReactTooltip
                      id="thien-can"
                      getContent={(dataTip) => (
                        <div>
                          <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                          <p style={{ color: "blue" }}>
                            Xin vui lòng
                            <span
                              className="click-span"
                              onClick={() => {
                                window.open(
                                  "https://www.phongthuytuongminh.com/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                                );
                              }}
                            >
                              {" "}
                              Click{" "}
                            </span>
                            vào đây để tìm hiểu thêm
                          </p>
                        </div>
                      )}
                      effect="solid"
                      delayHide={500}
                      delayShow={500}
                      delayUpdate={500}
                      place="top"
                      border={true}
                      type="light"
                    />
                  </tr>
                  <tr style={{ textAlign: "center", fontSize: "18px" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th
                        style={{ fontWeight: "normal" }}
                        colspan="3"
                        className={
                          dungThan.includes(nguHanhHour.split(" ")[1]) &&
                          checkDungThan
                            ? "border__ background"
                            : "border__"
                        }
                        id={
                          dungThan.includes(nguHanhHour.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                      >
                        {nguHanhHour}
                      </th>
                    )}
                    <th
                      id="bg__nhd"
                      style={{
                        fontWeight: "normal",
                        background: "#B2CFEA",
                        cursor: "pointer",
                      }}
                      colspan="3"
                      className={"border__"}
                      data-for="ngu-hanh"
                      data-tip={nguHanhDay}
                    >
                      {nguHanhDay}
                    </th>
                    <th
                      className={
                        dungThan.includes(nguHanhMonth.split(" ")[1]) &&
                        checkDungThan
                          ? "border__ background"
                          : "border__"
                      }
                      id={
                        dungThan.includes(nguHanhMonth.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                    >
                      {nguHanhMonth}
                    </th>
                    <th
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                      className={
                        dungThan.includes(nguHanhYear.split(" ")[1]) &&
                        checkDungThan
                          ? "border__ background"
                          : "border__"
                      }
                      id={
                        dungThan.includes(nguHanhYear.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                    >
                      {nguHanhYear}
                    </th>
                  </tr>
                  {/* dia chi  */}
                  <tr style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th colspan="3" className="fz__25 clr__ border__">
                        {diaChiHour}
                      </th>
                    )}

                    <th
                      id="bg__dcd"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__25 border__"
                    >
                      {diaChiDay}
                    </th>
                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      style={{ cursor: "pointer" }}
                      data-for="dung-than"
                      data-tip={thienCanDay + " Tháng " + diaChiMonth}
                    >
                      {diaChiMonth}
                    </th>

                    <th colspan="3" className="fz__25 clr__ border__">
                      {DiachiYear}
                    </th>

                    <th
                      id="bg__dc"
                      rowspan="1"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                      data-for="dia-chi"
                      data-tip="Địa Chi"
                    >
                      Địa Chi
                    </th>
                  </tr>

                  {/** tang can gio`*/}

                  <tr className="fz__18" style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : tangCanNguHanhHour2 ? (
                      <td
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour2}</sup>
                          {tangCanNguHanhHour2}
                        </div>
                        <div
                          classNamestyle={{
                            justifyContent: "center",
                            width: "47px",
                          }}
                        >
                          {tangCanNguHanhHour5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {checkHour ? (
                      ""
                    ) : tangCanNguHanhHour2 && tangCanNguHanhHour3 ? (
                      <th
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : "tcbox"
                        }
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    ) : tangCanNguHanhHour3 ? (
                      <th
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : "tcbox"
                        }
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        colspan="2"
                        style={{ verticalAlign: "middle", padding: "0" }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    ) : (
                      <th
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : "tcbox"
                        }
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        colspan="3"
                        style={{ verticalAlign: "middle", padding: "0" }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    )}

                    {checkHour ? (
                      ""
                    ) : tangCanNguHanhHour3 && tangCanNguHanhHour2 ? (
                      <td
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour3}</sup>
                          {tangCanNguHanhHour3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour6}
                        </div>
                      </td>
                    ) : tangCanNguHanhHour3 && tangCanNguHanhHour ? (
                      <td
                        id={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(
                            tangCanNguHanhHour4.split(" ")[1]
                          ) && checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour3}</sup>
                          {tangCanNguHanhHour3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can gio`*/}

                    {/** tang can ngay`*/}

                    {tangCanNguHanhDay2 ? (
                      <td
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd"
                        }
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                      >
                        <div>
                          <sup>{thapThanDay2}</sup>
                          {tangCanNguHanhDay2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhDay5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanNguHanhDay2 && tangCanNguHanhDay3 ? (
                      <th
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd2"
                        }
                        colspan="1"
                        style={{
                          border: "none",
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    ) : tangCanNguHanhDay3 ? (
                      <th
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd2"
                        }
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                        colspan="2"
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    ) : (
                      <th
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd2"
                        }
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    )}

                    {tangCanNguHanhDay3 && tangCanNguHanhDay2 ? (
                      <td
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd3"
                        }
                        colspan="1"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanDay3}</sup>
                          {tangCanNguHanhDay3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay6}
                        </div>
                      </td>
                    ) : tangCanNguHanhDay3 && tangCanNguHanhDay ? (
                      <td
                        className={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : "bg__tcd3"
                        }
                        colspan="1"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanDay3}</sup>
                          {tangCanNguHanhDay3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can ngay``*/}

                    {/** tang can thang*/}
                    {tangCanMonth2 ? (
                      <td
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth2}</sup>
                          {tangCanMonth2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanMonth2 && tangCanMonth3 ? (
                      <th
                        colspan="1"
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background"
                            : ""
                        }
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          border: "none",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth4}
                        </div>
                      </th>
                    ) : tangCanMonth3 ? (
                      <th
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="2"
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanMonth4}
                        </div>
                      </th>
                    ) : (
                      <th
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="3"
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                      >
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanMonth4}
                        </div>
                      </th>
                    )}

                    {tangCanMonth3 && tangCanMonth2 ? (
                      <td
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth3}</sup>
                          {tangCanMonth3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth6}
                        </div>
                      </td>
                    ) : tangCanMonth3 && tangCanMonth ? (
                      <td
                        id={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                          !checkThangDungThan &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth3}</sup>
                          {tangCanMonth3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                    {/** tang can nam*/}

                    {tangCanYear2 ? (
                      <td
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear2}</sup>
                          {tangCanYear2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanYear2 && tangCanYear3 ? (
                      <th
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="1"
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear4}
                        </div>
                      </th>
                    ) : tangCanYear3 ? (
                      <th
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="2"
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear4}
                        </div>
                      </th>
                    ) : (
                      <th
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        colspan="3"
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                      >
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear4}
                        </div>
                      </th>
                    )}

                    {tangCanYear3 && tangCanYear2 ? (
                      <td
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear3}</sup>
                          {tangCanYear3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear6}
                        </div>
                      </td>
                    ) : tangCanYear3 && tangCanYear ? (
                      <td
                        id={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        className={
                          dungThan.includes(tangCanYear4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear3}</sup>
                          {tangCanYear3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can nam*/}

                    <th
                      id="bg__tcc"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Tàng Can
                    </th>
                  </tr>

                  {/** nap am*/}
                  {checkNapAm ? (
                    <tr style={{ textAlign: "center" }}>
                      {checkHour ? (
                        ""
                      ) : (
                        <th id="nabox" colspan="3" className="fz__18 border__">
                          {napAmHour}
                        </th>
                      )}
                      <th
                        id="bg__nad"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__"
                      >
                        {hyperLinkNapAm(napAmDay)}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {hyperLinkNapAm(napAmMonth)}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {hyperLinkNapAm(napAmYear)}
                      </th>
                      <th
                        id="bg__na"
                        style={{ width: "58px", border: "3px solid #000000" }}
                        className="bg__ clr__white clr__print"
                        data-for="nap-am"
                        data-tip="Nạp Âm"
                      >
                        Nạp Âm
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** nap am*/}

                  {/** truong sinh*/}
                  {checkTruongSinh ? (
                    <tr style={{ textAlign: "center" }}>
                      {checkHour ? (
                        ""
                      ) : (
                        <th id="boxtss" colspan="3" className="fz__18 border__">
                          {truongSinhHour}
                        </th>
                      )}
                      <th
                        id="bg__tsd"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__"
                      >
                        {hyperLinkTruongSinh(truongSinhDay)}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {hyperLinkTruongSinh(truongSinhMonth)}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {hyperLinkTruongSinh(truongSinhYear)}
                      </th>
                      <th
                        id="bg__ts"
                        style={{ width: "58px", border: "3px solid #000000" }}
                        className="bg__ clr__white clr__print"
                        data-for="truong-sinh"
                        data-tip="Trường Sinh"
                      >
                        Trường Sinh
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** truong sinh*/}

                  {/** than sat*/}
                  {checkThanSat ? (
                    <tr style={{ textAlign: "center" }}>
                      {checkHour ? (
                        ""
                      ) : (
                        <th
                          id="tsbox"
                          colspan="3"
                          className="fz__18 border__ text__wrap"
                        >
                          {(thanSatThienCanHour && thanSatDiachiHours) ||
                          (KhongVongHour && thanSatDiachiHours)
                            ? [...hyperLinkThanSat(thanSatDiachiHours), ", "]
                            : hyperLinkThanSat(thanSatDiachiHours)}
                          {thanSatKhongVong(KhongVongHour)}
                        </th>
                      )}
                      <th
                        id="bg__tssd"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__ text__wrap"
                      >
                        {hyperLinkThanSat(thanSatDiaChiDay)}
                        {thanSatKhongVong(KhongVongDay)} {linkHoaGiap}
                      </th>
                      <th colspan="3" className="fz__18 border__ text__wrap">
                        {(thanSatThienCanMonth && thanSatDiachiMonth) ||
                        (KhongVongMonth && thanSatDiachiMonth)
                          ? [...hyperLinkThanSat(thanSatDiachiMonth), ", "]
                          : hyperLinkThanSat(thanSatDiachiMonth)}
                        {thanSatKhongVong(KhongVongMonth)}
                      </th>
                      <th colspan="3" className="fz__18 border__ text__wrap">
                        {(thanSatThienCanYear &&
                          thanSatDiaChiYear.length > 0) ||
                        thanSatDiaChiYear.length > 1
                          ? [...hyperLinkThanSatDiaChi(thanSatDiaChiYear)]
                          : hyperLinkThanSatDiaChi(thanSatDiaChiYear)}
                        {thanSatKhongVong(KhongVongYear)}
                      </th>
                      <th
                        id="bg__tss"
                        style={{
                          width: "58px",
                          border: "3px solid #000000",
                          cursor: "pointer",
                        }}
                        className="bg__ clr__white clr__print"
                        data-for="than-sat"
                        data-tip="Thần Sát"
                      >
                        Thần Sát
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** than sat*/}
                </tbody>
              </Table>

              <ReactTooltip
                id="dia-chi"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="nap-am"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/luan-giai-ngu-hanh-nap-am-hieu-dung-ve-mot-khai-niem-quan-trong-trong-phong-thuy.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="truong-sinh"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/vong-truong-sinh-trong-bat-tu-hieu-biet-sau-hon-de-cai-thien-cuoc-song.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="dai-van"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/dai-van-luu-nien-trong-bat-tu.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="luu-nien"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/dai-van-luu-nien-trong-bat-tu.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="huong-tot"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/cach-xac-dinh-huong-tot-huong-xau-trong-phong-thuy-chi-tiet-nhat.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="huong-xau"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/cach-xac-dinh-huong-tot-huong-xau-trong-phong-thuy-chi-tiet-nhat.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="dung-than"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          window.open(
                            "https://www.phongthuytuongminh.com/vi/dung-than-trong-bat-tu-la-gi-cach-bo-sung-dung-than-hieu-qua-nhat.html"
                          );
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />

              <div style={{ pageBreakBefore: "always" }}></div>

              {/** so sánh */}

              <SoSanh
                excelLichvanNien={excelLichvanNien}
                checkDungThan={checkDungThan}
              ></SoSanh>

              {/** so sánh */}

              <div>
                {checkDungThan && (
                  <div className="page-break-table-2">
                    <Table style={{ height: "auto" }}>
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th
                            id="bg__tc"
                            className="bg__ clr__white clr__print"
                            style={{
                              width: "198px",
                              border: "1px solid black",
                              fontSize: "18px",
                              color: "white",
                            }}
                          >
                            Cách cục
                          </th>
                          <th
                            id="bg__tc"
                            className="bg__ clr__white clr__print"
                            data-for="dung-than"
                            data-tip="Dụng Thần Phù Ức"
                            style={{
                              width: "198px",
                              border: "1px solid black",
                              fontSize: "18px",
                              color: "white",
                            }}
                          >
                            Dụng Thần Phù Ức
                          </th>
                          <th
                            id="bg__tc"
                            className="bg__ clr__white clr__print"
                            data-for="dung-than"
                            data-tip="Dụng Thần Thông Quan"
                            style={{
                              width: "198px",
                              border: "1px solid black",
                              fontSize: "18px",
                              color: "white",
                            }}
                          >
                            Dụng Thần Thông Quan
                          </th>
                          <th
                            id="bg__tc"
                            className="bg__ clr__white clr__print"
                            data-for="dung-than"
                            data-tip="Dụng Thần Điều Hậu"
                            style={{
                              width: "198px",
                              border: "1px solid black",
                              fontSize: "18px",
                              color: "white",
                            }}
                          >
                            Dụng Thần Điều Hậu
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td
                            style={{
                              width: "99px",
                              border: "1px solid black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {cachCuc}
                          </td>
                          <td
                            style={{
                              width: "99px",
                              border: "1px solid black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {dungThanPhuUc}
                          </td>
                          <td
                            style={{
                              width: "99px",
                              border: "1px solid black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {dungThanThongQuan}
                          </td>
                          <td
                            style={{
                              width: "99px",
                              border: "1px solid black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {dungThanDieuHau}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>

              <ReactTooltip
                id="tinh-cach"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>
                      {dataTip?.split(" ")[0] + " " + dataTip?.split(" ")[2]}
                    </h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          isActiveTinhCach
                            ? activeTab("tinhcach")
                            : setShowIsLogin(true);
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="dung-than"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          isActiveDungThanDieuHau
                            ? activeTab("dungthandieuhau")
                            : setShowIsLogin(true);
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <ReactTooltip
                id="ngu-hanh"
                getContent={(dataTip) => (
                  <div>
                    <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                    <p style={{ color: "blue" }}>
                      Xin vui lòng
                      <span
                        className="click-span"
                        onClick={() => {
                          isActiveNguHanh
                            ? activeTab("nguhanh")
                            : setShowIsLogin(true);
                        }}
                      >
                        {" "}
                        Click{" "}
                      </span>
                      vào đây để tìm hiểu thêm
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="top"
                border={true}
                type="light"
              />
              <div
                className="fz__18 block__print"
                style={{
                  marginTop: "50px",
                  marginBottom: "10px",
                  width: "700px",
                  display: "none",
                }}
              >
                Ghi chú: Ngày giờ sinh nhập theo dương lịch. Giờ Tý 23:00-00:59;
                Sửu 01:00-02:59...;
              </div>
              <div
                style={{ margin: "0 auto", width: "700px", display: "none" }}
                className="fz__18 block__print"
              >
                CA: Chính Ấn; TA: Thiên Ấn; CQ: Chính Quan; TS: Thất Sát; TK: Tỷ
                Kiên; KT: Kiếp Tài; CT: Chính Tài; TT: Thiên Tài; TQ: Thương
                Quan; TH: Thực Thần
              </div>
            </div>

            {/** dai van hien tai*/}
            <div
              style={{
                maxHeight: 882,
                height: 700,
              }}
            >
              <Table
                id="dvht"
                className="margin__print"
                style={{ width: "250px", maxHeight: "882px", height: "700px" }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid transparent" }} colspan="3">
                      <div
                        className="bg__2"
                        style={{
                          marginBottom: "9px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          className="hide-to-print"
                          onClick={handlePrevDaiVan}
                          style={{
                            width: "50%",
                            border: "none",
                            borderRight: "1px solid white",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleLeft style={{ color: "white" }} />
                        </button>
                        <button
                          className="hide-to-print"
                          onClick={handleNextDaiVan}
                          style={{
                            width: "50%",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleRight style={{ color: "white" }} />
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__dv"
                      colspan="3"
                      className="fz__22"
                      style={{
                        width: "150px",
                        height: "64px",
                        border: "3px solid #000000",
                        background: "#ff7400",
                        color: "#FFFFFF",
                      }}
                    >
                      {`${soNgay} (${soNam})`}
                    </th>

                    <th
                      id="bg__b"
                      style={{
                        width: "58px",
                        border: "0.15em solid #000000",
                        background: "#ff7400",
                        height: "64px",
                      }}
                    >
                      {" "}
                    </th>
                  </tr>
                  {/* thien can dai van */}
                  <tr
                    style={{
                      textAlign: "center",
                      height: "63.27px",
                      cursor: "pointer",
                    }}
                  >
                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      data-for="con-giap"
                      data-tip={thienCanDaiVan}
                    >
                      <sup className="fz__15">{ThapThanDaiVan}</sup>
                      {thienCanDaiVan}
                    </th>

                    <th
                      id="bg__dvht"
                      rowSpan="3"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                      data-for="dai-van"
                      data-tip="Đại Vận"
                    >
                      Đại Vận Hiện Tại
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{ fontWeight: "normal", height: "56.73px" }}
                      colspan="3"
                      className={
                        dungThan.includes(nguHanhTCDV.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(nguHanhTCDV.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                    >
                      {nguHanhTCDV}
                    </th>
                  </tr>
                  {/* thien can dai van */}

                  {/* dia chi dai van */}

                  <tr style={{ textAlign: "center" }}>
                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      style={{ height: "62.55px" }}
                    >
                      {diaChiDaiVan}
                    </th>
                  </tr>

                  {/* dia chi dai van */}

                  {/* tang can dai van */}
                  <tr
                    className={
                      dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    style={{ textAlign: "center", height: " 85.09px" }}
                  >
                    {TangCanDaiVan2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan2}</sup>
                          {TangCanDaiVan2}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {TangCanDaiVan2 && TangCanDaiVan3 ? (
                      <th
                        colspan="1"
                        className={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    ) : TangCanDaiVan3 ? (
                      <th
                        colspan="2"
                        className={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    ) : (
                      <th
                        colspan="3"
                        className={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                      >
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    )}

                    {TangCanDaiVan3 && TangCanDaiVan2 ? (
                      <td
                        className={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan3}</sup>
                          {TangCanDaiVan3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan6}
                        </div>
                      </td>
                    ) : TangCanDaiVan3 && TangCanDaiVan ? (
                      <td
                        className={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan
                            ? "background border__"
                            : "border__"
                        }
                        id={
                          dungThan.includes(TangCanDaiVan4.split(" ")[1]) &&
                          checkDungThan &&
                          "dungThan"
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan3}</sup>
                          {TangCanDaiVan3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    <th
                      id="bg__tcdv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Tàng Can
                    </th>
                  </tr>
                  {/* tang can dai van */}

                  {/* nap am dai van */}
                  <tr style={{ textAlign: "center", height: "84.36px" }}>
                    <th colspan="3" className="fz__18 border__">
                      {hyperLinkNapAm(napAmDaiVan)}
                    </th>
                    <th
                      id="bg__nadv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                      data-for="nap-am"
                      data-tip="Nạp Âm"
                    >
                      Nạp Âm
                    </th>
                  </tr>

                  {/* nap am dai van */}

                  {/* truong sinh dai van */}
                  <tr style={{ textAlign: "center", height: "80px" }}>
                    <th colspan="3" className="fz__18 border__">
                      {hyperLinkTruongSinh(TruongSinhDaiVan)}
                    </th>
                    <th
                      id="bg__tsdv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                      data-for="truong-sinh"
                      data-tip="Trường Sinh"
                    >
                      Trường Sinh
                    </th>
                  </tr>

                  {/* truong sinh dai van */}

                  {/* than sat dai van */}
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="dvhtts"
                      colspan="3"
                      className="fz__18 border__ text__wrap"
                    >
                      {(ThanSatThienCanDaiVan && ThanSatDiaChiDaiVan) ||
                      (KhongVongDaiVan && ThanSatDiaChiDaiVan)
                        ? [...hyperLinkThanSat(ThanSatDiaChiDaiVan)]
                        : hyperLinkThanSat(ThanSatDiaChiDaiVan)}
                      {hyperLinkThanSat(ThanSatThienCanDaiVan)}
                      {thanSatKhongVong(KhongVongDaiVan)}
                    </th>
                    <th
                      id="bg__dvts"
                      style={{
                        width: "58px",
                        border: "3px solid #000000",
                        cursor: "pointer",
                      }}
                      className="bg__2 clr__white clr__print"
                      data-for="than-sat"
                      data-tip="Thần Sát"
                    >
                      Thần Sát
                    </th>
                  </tr>
                  {/* than sat dai van */}
                </tbody>
              </Table>
            </div>

            {/** dai van hien tai*/}

            {/** luu nien hien tai*/}

            <Table
              id="lnht"
              className="margin__print"
              style={{
                width: "250px",
                marginLeft: "21px",
                maxHeight: "882px",
                height: "700px",

              }}
            >
              <thead>
                <tr>
                  <th colspan="3" style={{ border: "1px solid transparent" }}>
                    <div
                      className="bg__2"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        disabled={luuNienDisable}
                        onClick={handlePrevLuuNien}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        disabled={luuNienDisable}
                        className="hide-to-print"
                        onClick={handleNextLuuNien}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <th
                    id="bg__ln"
                    colspan="3"
                    className="fz__22"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#ff7400",
                      color: "#FFFFFF",
                    }}
                  >
                    {LuuNienState}
                  </th>

                  <th
                    id="bg__c"
                    style={{
                      width: "58px",
                      height: "64px",
                      border: "3px solid #000000",
                      background: "#ff7400",
                    }}
                  >
                    {" "}
                  </th>
                </tr>
                {/* thien can luu nien */}
                <tr
                  style={{
                    textAlign: "center",
                    height: "63.27px",
                    cursor: "pointer",
                  }}
                >
                  <th
                    colspan="3"
                    className="fz__25 clr__ border__"
                    data-for="con-giap"
                    data-tip={ThienCanLuuNien}
                  >
                    <sup className="fz__15">{thapthanLuuNien}</sup>
                    {ThienCanLuuNien}
                  </th>

                  <th
                    id="bg__lnht"
                    rowSpan="3"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                    data-for="luu-nien"
                    data-tip="Lưu Niên"
                  >
                    Lưu Niên Hiện Tại
                  </th>
                </tr>

                <tr style={{ textAlign: "center", height: "56.73px" }}>
                  <th
                    style={{ fontWeight: "normal" }}
                    colspan="3"
                    className={
                      dungThan.includes(nguHanhLN.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(nguHanhLN.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                  >
                    {nguHanhLN}
                  </th>
                </tr>
                {/* thien can luu nien */}

                {/* dia chi luu nien */}

                <tr style={{ textAlign: "center", height: "62.55px" }}>
                  <th colspan="3" className="fz__25 clr__ border__">
                    {DiaChiLuuNien}
                  </th>
                </tr>

                {/* dia chi luu nien */}

                {/* tang can luu nien */}
                <tr
                  className={
                    dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                    checkDungThan
                      ? "background border__"
                      : "border__"
                  }
                  id={
                    dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                    checkDungThan &&
                    "dungThan"
                  }
                  style={{ textAlign: "center", height: "85.09px" }}
                >
                  {TangCanLuuNien2 ? (
                    <td
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien2}</sup>
                        {TangCanLuuNien2}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {TangCanLuuNien2 && TangCanLuuNien3 ? (
                    <th
                      colspan="1"
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  ) : TangCanLuuNien3 ? (
                    <th
                      colspan="2"
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  ) : (
                    <th
                      colspan="3"
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                    >
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  )}

                  {TangCanLuuNien3 && TangCanLuuNien2 ? (
                    <td
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien3}</sup>
                        {TangCanLuuNien3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien6}
                      </div>
                    </td>
                  ) : TangCanLuuNien3 && TangCanLuuNien ? (
                    <td
                      className={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan
                          ? "background border__"
                          : "border__"
                      }
                      id={
                        dungThan.includes(TangCanLuuNien4.split(" ")[1]) &&
                        checkDungThan &&
                        "dungThan"
                      }
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien3}</sup>
                        {TangCanLuuNien3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  <th
                    id="bg__lntc"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Tàng Can
                  </th>
                </tr>
                {/* tang can luu nien */}

                {/* nap am luu nien */}
                <tr style={{ textAlign: "center", height: "84.36px" }}>
                  <th colspan="3" className="fz__18 border__">
                    {hyperLinkNapAm(napAmLuuNien?.napAm)}
                  </th>
                  <th
                    id="bg__lnna"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                    data-tip="Nạm Âm"
                    data-for="nap-am"
                  >
                    Nạp Âm
                  </th>
                </tr>

                {/* nap am luu nien */}

                {/* truong sinh luu nien */}
                <tr style={{ textAlign: "center", height: "80px" }}>
                  <th colspan="3" className="fz__18 border__">
                    {hyperLinkTruongSinh(TruongSinhLuuNien)}
                  </th>
                  <th
                    id="bg__lnts"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                    data-tip="Trường Sinh"
                    data-for="truong-sinh"
                  >
                    Trường Sinh
                  </th>
                </tr>

                {/* truong sinh luu nien */}

                {/* than sat luu nien */}
                <tr style={{ textAlign: "center", height: "80px" }}>
                  <th
                    id="lnhtts"
                    colspan="3"
                    className="fz__18 border__ text__wrap"
                  >

                      {(ThanSatThienCanLuuNien && ThanSatDiaChiLuuNien) ||
                    (KhongVongLN && ThanSatDiaChiLuuNien)
                      ? [...hyperLinkThanSat(ThanSatDiaChiLuuNien)]
                      : hyperLinkThanSat(ThanSatDiaChiLuuNien)}
                    {hyperLinkThanSat(ThanSatThienCanLuuNien)}
                    {thanSatKhongVong(KhongVongLN)}
                  </th>
                  <th
                    id="bg__lntss"
                    style={{
                      width: "58px",
                      border: "3px solid #000000",
                      cursor: "pointer",
                    }}
                    className="bg__2 clr__white"
                    data-for="than-sat"
                    data-tip="Thần Sát"
                  >
                    Thần Sát
                  </th>
                </tr>
                {/* than sat luu nien */}
              </tbody>
            </Table>
            {/** luu nien hien tai*/}
          </div>

          {/*<div*/}
          {/*  className="fz__18 hide-to-print"*/}
          {/*  style={{ margin: "10px auto", width: "700px" }}*/}
          {/*>*/}
          {/*  Ghi chú: Ngày giờ sinh nhập theo dương lịch. Giờ Tý 23:00-00:59; Sửu*/}
          {/*  01:00-02:59...;*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  style={{ margin: "0 auto", width: "700px" }}*/}
          {/*  className="fz__18 hide-to-print"*/}
          {/*>*/}
          {/*  CA: Chính Ấn; TA: Thiên Ấn; CQ: Chính Quan; TS: Thất Sát; TK: Tỷ*/}
          {/*  Kiên; KT: Kiếp Tài; CT: Chính Tài; TT: Thiên Tài; TQ: Thương Quan;*/}
          {/*  TH: Thực Thần*/}
          {/*</div>*/}

          <div
            id="table_dv"
            style={{
              width: "1440px",
              margin: "0 auto",
              zIndex: "1000",
              position: "relative",
            }}
          >
            <Table
              hover
              bordered
              style={{
                textAlign: "center",
                marginTop: "35px",
              }}
            >
              <thead>
                <tr>
                  <th
                    id="bg__lnn"
                    className="bg__ clr__white fz__22"
                    colspan="10"
                    data-for="dai-van"
                    data-tip="Đại Vận"
                    style={{ cursor: "pointer" }}
                  >
                    Đại Vận
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="fz__16">
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang1"
                            : ""
                        }
                        style={{ width: "145px", cursor: "pointer" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {x.soNgay} ({x.year})
                      </td>
                    );
                  })}

                  <th
                    id="bg__age"
                    className="bg__ clr__white fz__15 fw__b"
                    style={{ width: "58px" }}
                  >
                    Tuổi
                  </th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  {daiVanData.map((x) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        id={
                          x?.year === new Date().getFullYear() ||
                          (x?.year <= new Date().getFullYear() &&
                            x?.year >= new Date().getFullYear() - 10)
                            ? "bg_hang2"
                            : ""
                        }
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "fz__22 clr__ border__1px background_"
                            : "fz__22 clr__ border__1px"
                        }
                      >
                        <div data-for="con-giap" data-tip={x.thienCan}>
                          <sup
                            className="fz__15"
                            style={{ paddingRight: "4px" }}
                          >
                            {x.thapThan?.sinhKhac}
                          </sup>

                          {x.thienCan}
                        </div>
                      </td>
                    );
                  })}

                  <th
                    id="bg__lnntc"
                    rowSpan="2"
                    className="bg__ clr__white fz__15 fw__b"
                    data-for="thien-can"
                    data-tip="Thiên Can"
                  >
                    Thiên Can
                  </th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  {daiVanData.map((x) => {
                    return (
                      <th
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        id={
                          dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          checkDungThan
                            ? "dungThan"
                            : x?.year <= new Date().getFullYear() &&
                              x?.year > new Date().getFullYear() - 10
                            ? "bg_hang3"
                            : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                              x?.year <= new Date().getFullYear() &&
                              x?.year > new Date().getFullYear() - 10 &&
                              checkDungThan
                            ? "dungThan"
                            : ""
                        }
                        style={{ fontWeight: "normal", cursor: "pointer" }}
                        colspan="1"
                        className={
                          dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          checkDungThan
                            ? "fz__15 border__1px background"
                            : x?.year <= new Date().getFullYear() &&
                              x?.year > new Date().getFullYear() - 10
                            ? "fz__15 border__1px background_"
                            : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                              x?.year <= new Date().getFullYear() &&
                              x?.year > new Date().getFullYear() - 10 &&
                              checkDungThan
                            ? "fz__15 border__1px background"
                            : "fz__15 border__1px"
                        }
                      >
                        {x.nguHanhTC}
                      </th>
                    );
                  })}
                </tr>

                <tr>
                  {daiVanData.map((x) => (
                    <td
                      onClick={(e) =>
                        setYearDaiVanClone({
                          year: x?.year,
                          soTuoi: x?.soNgay,
                          show: true,
                        })
                      }
                      id={
                        x?.year <= new Date().getFullYear() &&
                        x?.year > new Date().getFullYear() - 10
                          ? "bg_hang4"
                          : ""
                      }
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      className={
                        x?.year <= new Date().getFullYear() &&
                        x?.year > new Date().getFullYear() - 10
                          ? "fz__22 clr__ border__1px background_"
                          : "fz__22 clr__ border__1px"
                      }
                    >
                      {x.diaChi}
                    </td>
                  ))}

                  <th
                    id="bg__lnndc"
                    rowSpan="1"
                    className="bg__ clr__white fz__15 fw__b"
                    data-for="dia-chi"
                    data-tip="Địa Chi"
                  >
                    Địa Chi
                  </th>
                </tr>

                <tr className="fz__10">
                  {tangCanDaiVanArr?.map((val) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: val?.year,
                            soTuoi: val?.soNgay,
                            show: true,
                          })
                        }
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "bg_hang5"
                            : ""
                        }
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          cursor: "pointer",
                        }}
                        className={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "border__1px background"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {val.TangCan[1]?.name ? (
                          <td
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none_bg"
                                : "none"
                            }
                            style={{
                              border: "none",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan2?.sinhKhac}</sup>

                              {val?.TangCan[1]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[1]?.nguHanh}</div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {val?.TangCan[1]?.name && val?.TangCan[2]?.nguHanh ? (
                          <th
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none3_bg"
                                : "none3"
                            }
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            style={{
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderTop: "none",
                              borderBottom: "none",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        ) : val?.TangCan[2]?.name ? (
                          <th
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none_bg4"
                                : "none4"
                            }
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            style={{
                              borderLeft: "none",
                              borderRight: "1px solid black",
                              borderTop: "none",
                              borderBottom: "none",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        ) : (
                          <th
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{
                              border: "none",
                              display: "inline-block",
                              margin: 0,
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        )}

                        {val?.TangCan[2]?.name && val?.TangCan[1]?.name ? (
                          <td
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{
                              border: "none",
                              padding: "0 5px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan3?.sinhKhac}</sup>

                              {val?.TangCan[2]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[2]?.nguHanh}</div>
                            </div>
                          </td>
                        ) : (
                          <td
                            className={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "background"
                                : ""
                            }
                            id={
                              dungThan.includes(
                                val?.TangCan[0]?.nguHanh.split(" ")[1]
                              ) && checkDungThan
                                ? "dungThan"
                                : val?.year <= new Date().getFullYear() &&
                                  val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{
                              border: "none",
                              padding: "0 23px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan3?.sinhKhac}</sup>

                              {val?.TangCan[2]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[2]?.nguHanh}</div>
                            </div>
                          </td>
                        )}
                      </td>
                    );
                  })}

                  <th id="bg__lnntcc" className="bg__ clr__white fz__15 fw__b">
                    Tàng Can
                  </th>
                </tr>
                <tr className="fz__16">
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        style={{ cursor: "pointer" }}
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang6"
                            : ""
                        }
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {hyperLinkNapAm(x.napAm?.napAm)}
                      </td>
                    );
                  })}
                  <th id="bg__lnnna" className="bg__ clr__white fz__15 fw__b">
                    Nạp Âm
                  </th>
                </tr>
                {/* nap am */}
                <tr>
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        style={{ cursor: "pointer" }}
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang7"
                            : ""
                        }
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px fz__16 background_"
                            : "border__1px fz__16"
                        }
                      >
                        {hyperLinkTruongSinh(x.truongSinh)}
                      </td>
                    );
                  })}
                  <th
                    id="bg__lnnts"
                    className="bg__ clr__white fz__15 fw__b"
                    data-for="truong-sinh"
                    data-tip="Trường Sinh"
                  >
                    Trường Sinh
                  </th>
                </tr>
                <tr>
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        onClick={(e) =>
                          setYearDaiVanClone({
                            year: x?.year,
                            soTuoi: x?.soNgay,
                            show: true,
                          })
                        }
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang8"
                            : ""
                        }
                        style={{ width: "150px", cursor: "pointer" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px fz__16 background_"
                            : "border__1px fz__16"
                        }
                      >
                        {(x.thanSatThienCan && x.thanSatDiaChi) ||
                        (x?.khongVong && x.thanSatDiaChi)
                          ? [...hyperLinkThanSat(x.thanSatDiaChi)]
                          : hyperLinkThanSat(x.thanSatDiaChi)}
                        {x.thanSatThienCan}
                        {thanSatKhongVong(x?.khongVong)}
                      </td>
                    );
                  })}
                  <th
                    id="bg__lnntss"
                    className="bg__ clr__white fz__15 fw__b"
                    data-for="than-sat"
                    data-tip="Thần Sát"
                    style={{ cursor: "pointer" }}
                  >
                    Thần Sát
                  </th>
                </tr>
              </tbody>
            </Table>
            <ReactTooltip
              id="dai-van"
              getContent={(dataTip) => (
                <div>
                  <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                  <p style={{ color: "blue" }}>
                    Xin vui lòng{" "}
                    <span
                      className="click-span"
                      onClick={() => {
                        window.open(
                          "https://www.phongthuytuongminh.com/dai-van-luu-nien-trong-bat-tu.html"
                        );
                      }}
                    >
                      Click
                    </span>{" "}
                    vào đây để tìm hiểu thêm
                  </p>
                </div>
              )}
              effect="solid"
              delayHide={500}
              delayShow={500}
              delayUpdate={500}
              place="top"
              border={true}
              type="light"
            />
            <ReactTooltip
              id="than-sat"
              getContent={(dataTip) => (
                <div>
                  <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                  <p style={{ color: "blue" }}>
                    Xin vui lòng{" "}
                    <span
                      className="click-span"
                      onClick={() => {
                        window.open(
                          "https://www.youtube.com/watch?v=v_CaGu2sP_0"
                        );
                      }}
                    >
                      Click
                    </span>{" "}
                    vào đây để tìm hiểu thêm
                  </p>
                </div>
              )}
              effect="solid"
              delayHide={500}
              delayShow={500}
              delayUpdate={500}
              place="top"
              border={true}
              type="light"
            />
          </div>

          <div
            id="table_lnn"
            className="page__2_print"
            style={{
              width: "1440px",
              margin: "0 auto",
              zIndex: "1000",
              position: "relative",
              top: "-17px",
            }}
          >
            {yearDaiVanClone.show && (
              <DaiVanClone
                yearDaiVan={yearDaiVanClone.year}
                thienCanNgay={thienCanNgayRef.current}
                soTuoi={yearDaiVanClone.soTuoi}
                excelLichvanNien={excelLichvanNien}
                dungThan={dungThan}
                checkDungThan={checkDungThan}
              />
            )}

            <ReactTooltip
              id="con-giap"
              getContent={(dataTip) => (
                <div>
                  <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                  <p style={{ color: "blue" }}>
                    Xin vui lòng{" "}
                    <span
                      className="click-span"
                      onClick={() => {
                        if (dataTip === "Giáp") {
                          window.open(
                            "https://www.youtube.com/watch?v=N9E8dt6mtHc&t=31s"
                          );
                        } else if (dataTip === "Ất") {
                          window.open(
                            "https://www.youtube.com/watch?v=phqBCvqlLVk"
                          );
                        } else if (dataTip === "Bính") {
                          window.open(
                            "https://www.youtube.com/watch?v=MWg_sgJxcto"
                          );
                        } else if (dataTip === "Đinh") {
                          window.open(
                            "https://www.youtube.com/watch?v=-QB6i5bWwxE"
                          );
                        } else if (dataTip === "Mậu") {
                          window.open(
                            "https://www.youtube.com/watch?v=qs5jw6rGNBI"
                          );
                        } else if (dataTip === "Kỷ") {
                          window.open(
                            "https://www.youtube.com/watch?v=7Q3ivslkQk4"
                          );
                        } else if (dataTip === "Canh") {
                          window.open(
                            "https://www.youtube.com/watch?v=cNZGmL6mpAI"
                          );
                        } else if (dataTip === "Tân") {
                          window.open(
                            "https://www.youtube.com/watch?v=xR1EIu_J6oo"
                          );
                        } else if (dataTip === "Nhâm") {
                          window.open(
                            "https://www.youtube.com/watch?v=SUdNG6Zuoow"
                          );
                        } else if (dataTip === "Quý") {
                          window.open(
                            "https://www.youtube.com/watch?v=thOn-UpGob8&t=8s"
                          );
                        }
                      }}
                    >
                      Click
                    </span>{" "}
                    vào đây để tìm hiểu thêm
                  </p>
                </div>
              )}
              effect="solid"
              delayHide={500}
              delayShow={500}
              delayUpdate={500}
              place="top"
              border={true}
              type="light"
            />
          </div>

          <div style={{ pageBreakBefore: "always" }}></div>
          <div
            id="page-title-note"
            style={{
              display: "none",
            }}
          >
            <h2>GHI CHÚ</h2>
          </div>
          <div
            id="page-note"
            style={{ display: "none" }}
            dangerouslySetInnerHTML={{ __html: dataNote }}
          ></div>
        </div>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        >
          {!excelLichvanNien || excelLichvanNien.length === 0
            ? "Đang setup dữ liệu, vui lòng đợi trong giây lát..."
            : "Vui lòng nhập thông tin và xem bát tự"}
        </div>
      )}
      {alerError ? (
        <SweetAlert
          type={"warning"}
          title={"Vui lòng đăng nhập để sử dụng website"}
          onConfirm={() => setAlerError(false)}
        />
      ) : (
        ""
      )}

      {alerError2 ? (
        <SweetAlert
          type={"warning"}
          title={"Tài khoản của bạn đã hết hạn"}
          onConfirm={() => setAlerError2(false)}
        />
      ) : (
        ""
      )}

      {showIsLogin ? (
        <SweetAlert
          type={"warning"}
          title={"Vui lòng kích hoạt để sử dụng chức năng này!"}
          onConfirm={() => setShowIsLogin(false)}
        />
      ) : (
        ""
      )}
      {dungThanActive ? (
        <SweetAlert
          type={"warning"}
          title={"Vui lòng nhập code để sử dụng tính năng này!"}
          onConfirm={() => setDungThanActive(false)}
        />
      ) : (
        ""
      )}
    </>
  );
};
